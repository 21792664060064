import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    taxWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }
})
