import React from 'react'
import {Grid} from '@material-ui/core'
import {IconDescription} from '../../IconDescription'

export type AmenityItemProps = {
    amenities?: string[]
}

export const AmenityItems: React.FC<AmenityItemProps> = ({amenities}) => {
    return amenities?.length ? (
        <Grid container item xs={12}>
            {amenities.map((item) =>
                <Grid xs={4} key={item} container item><IconDescription key={item} description={item}/></Grid>)}
        </Grid>
    ) : <></>
}