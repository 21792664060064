import { makeStyles } from '@material-ui/core'
import { COLORS } from 'common/enums/colors'
import { RADIUS } from 'common/enums/radius'

export const useStyles = makeStyles(() => {
  return {
    box: {
      width: '100%',
      height: 200,
      borderRadius: RADIUS.ACCORDION_RADIUS,
      backgroundColor: COLORS.GREY_LIGHT,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
})
