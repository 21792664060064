import React, { useContext } from 'react'
import { Box } from '@material-ui/core'
import { CustomTypography } from '../../Typography'
import { FONTS } from 'common/enums/fonts'
import download from 'assets/images/actions/download.svg'
import see from 'assets/images/actions/see.svg'
import { ContractInfoProps } from '../index'
import { useStyles } from '../styles'
import { IconLink } from '../../IconLink'
import { TeaserContext } from 'context/TeaserContext'
import { useNavigate } from 'react-router-dom'

export const ContractDetails: React.FC<ContractInfoProps> = ({ date, text, link }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { teaser } = useContext(TeaserContext)

  const navigateToTeaserSigned = () => {
    navigate(`/teasers/${teaser?.id}/signed`)
  }

  return (
    <Box className={classes.info}>
      <Box>
        <CustomTypography value={text} type={FONTS.H4_BLACK_80} component="h6" />
        <CustomTypography value={date} type={FONTS.H5_BLACK_60} component="h6" />
      </Box>
      <Box className={classes.icons}>
        <Box onClick={navigateToTeaserSigned}>
          <IconLink key={see} title={link} icon={see} />
        </Box>
        <IconLink key={download} url={link} title={link} icon={download} />
      </Box>
    </Box>
  )
}
