import React from 'react'
import {Box} from '@material-ui/core'
import {FONTS, FONTS_TAG_TYPE} from 'common/enums/fonts'
import {CustomTypography} from '../../Typography'
import {useStyles} from './styles'

type CardDescriptionType = {
    title: string
    description: string
}

export const CardDescription: React.FC<CardDescriptionType> = ({description, title}) => {
    const styles = useStyles()

    return (
        <Box className={styles.marginBox}>
            <CustomTypography
                component={FONTS_TAG_TYPE.H4}
                type={FONTS.H4_BLACK_80}
                value={title}
            />
            <CustomTypography
                component={FONTS_TAG_TYPE.H5}
                type={FONTS.H5_BLACK_60}
                value={description}
            />
        </Box>
    )
}
