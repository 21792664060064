export const title = 'תודה רבה!'

export enum ROLES {
  CLIENT = 'CLIENT',
  COLLABORATION = 'COLLABORATION',
}

export enum ROLES_TEXT {
  CLIENT_TEXT = 'ההסכם לתיווך הנכס נחתם בהצלחה. אפשר להוריד או לשלוח את ההסכם.',
  COLLABORATION_TEXT = 'ההסכם לתיווך בשותפות על הנכס נחתם בהצלחה. אפשר להוריד או לשלוח את ההסכם.',
  DEFAULT = 'ההסכם לתיווך לבעלות על נכס נחתם בהצלחה. אפשר להוריד או לשלוח את ההסכם.',
}
