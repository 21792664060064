import { RADIUS } from '../enums/radius'
import { SHADOW } from '../enums/shadow'

export const buttonTheme = () => ({
  MuiButton: {
    contained: {
      borderRadius: RADIUS.BUTTON_RADIUS,
      border: 0,
      boxShadow: SHADOW.SHADOW_NONE,
    },
    root: {
      width: '100%',
      height: 55,
    },
    label: {
      fontWeight: 700,
      fontSize: 16,
    },
  },
})
