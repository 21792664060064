import {Routing} from './routes'
import {useEffect} from 'react'
import packageJson from '../package.json'

function App() {
    useEffect(() => {
        document.title = `Teaser v${packageJson.version}`
    }, [])
    return (
        <div className='app-wrapper' dir='rtl'>
            <Routing/>
        </div>
    )
}

export default App
