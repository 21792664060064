import React from 'react'
import { TeaserContractDetails } from '../../TeaserContractDetails'
import { IData } from '../../../types'

type TeaserAgreementsType = {
  teaser: IData
}

export const TeaserAgreements: React.FC<TeaserAgreementsType> = ({ teaser }) => {
  return (
    <>
      <TeaserContractDetails content={teaser.agreement.content} />

      {/* <TeaserBrokerageDetails
        sellCommission={teaser.agreement.sellCommission}
        rentCommission={teaser.agreement.rentCommission}
      /> */}
    </>
  )
}
