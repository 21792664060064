import React from 'react'
import { Accordion } from '@material-ui/core'
import { useStyles } from './styles'
import { PropertyInterface } from 'interfaces/teaser.interface'
import { CustomAccordionSummary } from './AccordionSummary'
import { CustomAccordionDetails } from './AccordionDetails'

export type CustomAccordionProps = {
  property: PropertyInterface
  showFullAddress: boolean
  isExpanded?: boolean
}

export const CustomAccordion: React.FC<CustomAccordionProps> = ({ property, showFullAddress, isExpanded }) => {
  const styles = useStyles()
  return (
    <Accordion className={styles.accordion} defaultExpanded={isExpanded}>
      <CustomAccordionSummary property={property} showFullAddress={showFullAddress} />
      <CustomAccordionDetails property={property} showFullAddress={showFullAddress} />
    </Accordion>
  )
}
