import accessibility from 'assets/images/amenities/accesibility.svg'
import emergency from 'assets/images/amenities/mamak.svg'
import guard from 'assets/images/amenities/guard.svg'
import waterheater from 'assets/images/amenities/dud.svg'
import airCondition from 'assets/images/amenities/airC.svg'
import tenants from 'assets/images/amenities/dayarim room.svg'
import elevator from 'assets/images/amenities/elevator.svg'
import furniture from 'assets/images/amenities/Group 33703.svg'
import garden from 'assets/images/amenities/Group 34036.svg'
import mamad from 'assets/images/amenities/mamad.svg'
import gym from 'assets/images/amenities/gym.svg'
import parking from 'assets/images/amenities/parking.svg'
import pool from 'assets/images/amenities/pool.svg'
import balcony from 'assets/images/amenities/porch.svg'
import curtains from 'assets/images/amenities/bars.svg'
import parents from 'assets/images/amenities/Group 34037.svg'
import renovated from 'assets/images/amenities/const.svg'
import wearhouse from 'assets/images/amenities/wearhouse.svg'

export type AmenityProps = {
  heb: string
  eng: string
  img: string
}

export const AMENITIES: AmenityProps[] = [
  {
    heb: 'ח. דיירים',
    eng: 'TENANTS',
    img: tenants,
  },
  {
    heb: 'מרפסת',
    eng: 'BALCONY',
    img: balcony,
  },
  {
    heb: 'מעלית',
    eng: 'ELEVATOR',
    img: elevator,
  },
  {
    heb: 'בריכה',
    eng: 'POOL',
    img: pool,
  },
  {
    heb: 'ריהוט',
    eng: 'FURNITURE',
    img: furniture,
  },
  {
    heb: 'חנייה',
    eng: 'PARKING',
    img: parking,
  },
  {
    heb: 'דוד״ש',
    eng: 'WATERHEATER',
    img: waterheater,
  },
  {
    heb: 'סורגים',
    eng: 'CURTAINS',
    img: curtains,
  },
  {
    heb: 'ממ״ד',
    eng: 'MAMAD',
    img: mamad,
  },
  {
    heb: 'מחסן',
    eng: 'WEARHOUSE',
    img: wearhouse,
  },
  {
    heb: 'שומר',
    eng: 'GUARD',
    img: guard,
  },
  {
    heb: 'משופץ',
    eng: 'RENOVATED',
    img: renovated,
  },
  {
    heb: 'נגיש',
    eng: 'ACCESSIBILITY',
    img: accessibility,
  },
  {
    heb: 'ממ׳׳ק',
    eng: 'EMERGENCY',
    img: emergency,
  },
  {
    heb: 'חדר כושר',
    eng: 'GYM',
    img: gym,
  },
  {
    heb: 'ח. הורים',
    eng: 'PARENTS',
    img: parents,
  },
  {
    heb: 'גינה',
    eng: 'GARDEN',
    img: garden,
  },
  {
    heb: 'מזגנים',
    eng: 'AIR_CONDITION',
    img: airCondition,
  },
]
