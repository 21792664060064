import { COLORS } from 'common/enums/colors'
import { RADIUS } from 'common/enums/radius'

export const inputTheme = () => ({
  MuiInputBase: {
    root: {
      width: '100%',
      border: '1px solid !important',
      borderRadius: RADIUS.INPUT_RADIUS,
      borderColor: `${COLORS.GREY} !important`,
      '&:hover': {
        borderColor: `${COLORS.GREY_DARK} !important`,
        backgroundColor: `${COLORS.WHITE} !important`,
      },
      '&$focused': {
        borderColor: `${COLORS.GREY_DARK} !important`,
        backgroundColor: `${COLORS.WHITE} !important`,
      },
    },
  },
  MuiFilledInput: {
    root: {
      width: '100%',
      height: 40,
      padding: '0 25px',
      fontSize: 16,
      backgroundColor: 'none',
      border: '1px solid',
      borderColor: COLORS.GREY_LIGHT,
      '&$focused': {
        borderColor: COLORS.PINK,
      },
      caretColor: COLORS.PINK,
    },
    input: {
      fontFamily: 'Noto Sans Hebrew, Helvetica Noto Sans, Roboto, Arial, sans-serif !important',
      padding: '4px 0 0 0 !important',
      '&:-webkit-autofill': {
        'border-top-right-radius': 0,
        'border-top-left-radius': 0,
        '-webkit-box-shadow': '0 0 0 50px white inset' /* Change the color to your own background color */,
      },
    },
    underline: {
      '&:before, &:after, &:hover::before': {
        borderBottom: 'none',
      },
    },
  },
  MuiInputLabel: {
    root: {
      fontFamily: 'Noto Sans Hebrew, Helvetica Noto Sans, Roboto, Arial, sans-serif !important',
      marginTop: -7.5,
      padding: '0 10px !important',
      '&$focused': {
        color: 'transparent',
      },
    },
    shrink: {
      color: COLORS.PINK,
    },
  },
  MuiButtonLabel: {
    root: {
      fontFamily: 'Noto Sans Hebrew, Helvetica Noto Sans, Roboto, Arial, sans-serif !important',
    },
  },
})
