import React from 'react'
import { DESCRIPTION, FILES, FILES_TYPE } from 'common/enums/texts'
import { WhiteContainer } from '../containers/WhiteContainer'
import { PropertyDescription } from '../PropertyDescription'
import { Attachments } from './Attachments'
import { LinkType } from 'interfaces/teaser.interface'

export type AttachmentsDetailsProps = {
  attachments: LinkType[]
}

export const AttachmentsDetails: React.FC<AttachmentsDetailsProps> = ({ attachments }) => {
  return (
    <WhiteContainer>
      <PropertyDescription
        title={DESCRIPTION.ARCH_PLANS}
        description={`${FILES.FORMATS}: ${FILES_TYPE.XLSX}, ${FILES_TYPE.PDF}, ${FILES_TYPE.DOCX}`}
      />
      {attachments.length > 0 ? <Attachments attachments={attachments} /> : '-'}
    </WhiteContainer>
  )
}
