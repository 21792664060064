// import { webViewPropertyDataType } from './types'
import { PROPERTIES } from './const'
import { instance } from './teaser'

// export const fetchPropertyById = async (webViewData: string, propertyId: string) => {
//   const dataRequest: webViewPropertyDataType = JSON.parse(webViewData)
//   try {
//     const response = await instance.get(`${PROPERTIES}/${propertyId}`, {
//       headers: {
//         Authorization: `Bearer ${dataRequest.token}`,
//         'Agency-Id': `${dataRequest.agencyId}`,
//       },
//     })
//     return response.data.data
//   } catch (error) {
//     return error.response.data
//   }
// }

export const fetchPropertyById = async (propertyId: string | undefined) => {
  try {
    const response = await instance.get(`${PROPERTIES}/${propertyId}`)
    return response.data.data
  } catch (error) {
    return error.response.data
  }
}
