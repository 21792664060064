import { createTheme } from '@material-ui/core'
import { COLORS } from '../enums/colors'
import { accordionTheme } from './accordion'
import { buttonTheme } from './button'
import { drawerTheme } from './drawer'
import { iconTheme } from './icons'
import { inputTheme } from './input'
import { switchTheme } from './switch'
import { typographyTheme } from './typography'

export const globalTheme = createTheme({
  direction: 'rtl',
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1350,
      lg: 1500,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: COLORS.BLACK,
      light: COLORS.BLACK_LIGHT,
    },
    secondary: {
      main: COLORS.PINK,
      light: COLORS.PINK_LIGHT,
    },
    info: {
      main: COLORS.GREY,
      light: COLORS.GREY_LIGHT,
    },
    error: {
      main: COLORS.PINK,
    },
  },
  overrides: {
    ...typographyTheme(),
    ...buttonTheme(),
    ...inputTheme(),
    ...accordionTheme(),
    ...switchTheme(),
    ...drawerTheme(),
    ...iconTheme(),
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
})
