import React, {useEffect, useState} from 'react'
import {Avatar, Box} from '@material-ui/core'
import {FONTS, FONTS_TAG_TYPE} from 'common/enums/fonts'
import {CustomTypography} from '../Typography'
import {useStyles} from './styles'
import {AMENITIES, AmenityProps} from './const'

type IconDescriptionProps = {
    description: string
}

export const IconDescription: React.FC<IconDescriptionProps> = ({description}) => {
    const [amenities, setAmenities] = useState<AmenityProps[]>([])
    const styles = useStyles()

    useEffect(() => {
        setAmenities(AMENITIES.filter((amenity) => amenity.heb === description))
    }, [description])

    return amenities.length ? (
        <Box className={styles.iconWrapper}>
            <Avatar alt={amenities[0].eng} src={amenities[0].img} className={styles.icon}/>
            <CustomTypography
                component={FONTS_TAG_TYPE.H5}
                type={FONTS.H5_BLACK_60}
                value={description}
            />
        </Box>
    ) : <></>
}
