export enum LABEL_TYPES {
  FULLNAME = 'signeeFullName',
  ID = 'signeeNationalId',
  ADDRESS = 'signeeAddress',
}

export enum LABELS {
  FULLNAME = 'שם מלא',
  ID = 'תעודת זהות',
  ADDRESS = 'כתובת מגורים',
}

export enum INPUTS_ERRORS_TEXT {
  teudatZeut = 'ת.ז. צריכה להיות מורכבת רק ממספרים',
  teudatZeutLength = 'ת.ז. צריך להיות בדיוק 9 מספרים',
  name = 'שם לקוח צריך להכיל יותר מאות אחת',
  default = 'שדה חובה',
}

export const nameRegex = /^[a-z\u0590-\u05fe\s]+$/i
export const addressRegex = /^[a-z\u0590-\u05fe\d\s.,]+$/i
export const idRegex = /^\d{9}$/
