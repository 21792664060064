import { makeStyles } from '@material-ui/core'
import { COLORS } from '../../common/enums/colors'

export const useStyles = makeStyles(theme => {
  return {
    H1_BLACK_60: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      opacity: '.6 !important',
      ...theme?.overrides?.MuiTypography?.h1,
      '@media (max-width: 400px)': {
        fontSize: '5.2vw',
      },
    },
    H2_BLACK: {
      color: theme.palette.primary.main,
      ...theme?.overrides?.MuiTypography?.h2,
    },
    H2_BLACK_16: {
      color: theme.palette.primary.main,
      ...theme?.overrides?.MuiTypography?.h2,
      fontSize: 16,
    },
    H2_BLACK_18: {
      color: theme.palette.primary.main,
      ...theme?.overrides?.MuiTypography?.h2,
      fontSize: 18,
    },
    H2_BLACK_80: {
      color: theme.palette.primary.main,
      opacity: '.8 !important',
      ...theme?.overrides?.MuiTypography?.h2,
    },
    H3_BLACK: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      opacity: 0.8,
      ...theme?.overrides?.MuiTypography?.h3,
    },
    H3_BLACK_60: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      opacity: '.6 !important',
      ...theme?.overrides?.MuiTypography?.h3,
    },
    H3_PINK: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      opacity: '.6 !important',
      ...theme?.overrides?.MuiTypography?.h3,
    },
    H3_PINK_400: {
      color: theme.palette.secondary.main,
      fontWeight: 400,
      opacity: '.6 !important',
      ...theme?.overrides?.MuiTypography?.h3,
    },
    H3_PINK_18: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      opacity: '.6 !important',
      ...theme?.overrides?.MuiTypography?.h3,
      fontSize: 18,
    },
    H4_BLACK: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      ...theme?.overrides?.MuiTypography?.h4,
    },
    H4_BLACK_500: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      ...theme?.overrides?.MuiTypography?.h4,
    },
    H4_BLACK_80: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      ...theme?.overrides?.MuiTypography?.h4,
      opacity: 0.8,
    },
    H4_PINK_60: {
      color: theme.palette.secondary.main,
      opacity: '.6 !important',
      ...theme?.overrides?.MuiTypography?.h4,
    },
    H4_WHITE: {
      color: COLORS.WHITE,
      fontWeight: 600,
      ...theme?.overrides?.MuiTypography?.h4,
    },
    H5_PINK_60: {
      color: theme.palette.secondary.main,
      opacity: '.6 !important',
      ...theme?.overrides?.MuiTypography?.h5,
    },
    H5_BLACK_60: {
      color: theme.palette.primary.main,
      opacity: '.6 !important',
      ...theme?.overrides?.MuiTypography?.h5,
    },
    H5_BLACK_80: {
      color: theme.palette.primary.main,
      opacity: '.8 !important',
      ...theme?.overrides?.MuiTypography?.h5,
    },
    H6_BLACK_40: {
      color: theme.palette.primary.main,
      opacity: '.4 !important',
      ...theme?.overrides?.MuiTypography?.h6,
    },
    P_Regular_Black_80: {
      color: theme.palette.primary.main,
      opacity: '.8 !important',
      ...theme?.overrides?.MuiTypography?.subtitle1,
    },
    P_Regular_Black_60: {
      color: theme.palette.primary.main,
      opacity: '.6 !important',
      overflow: 'hidden',
      ...theme?.overrides?.MuiTypography?.subtitle1,
    },
    P_HTML_Black_60: {
      color: theme.palette.primary.main,
      opacity: '.6 !important',
      overflow: 'hidden',
      ...theme?.overrides?.MuiTypography?.subtitle1,
    },
    P_LIGHT_PINK_60: {
      color: theme.palette.secondary.main,
      fontWeight: 300,
      opacity: '.6 !important',
      ...theme?.overrides?.MuiTypography?.subtitle2,
    },
  }
})
