import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    infoBlock: {
      minHeight: 75,
    },
    leftBox: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
  }
})
