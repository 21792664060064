import {Box} from '@material-ui/core'
import {CustomTypography} from 'components/Typography'
import {FONTS, FONTS_TAG_TYPE} from 'common/enums/fonts'
import React from 'react'
import {FONTS_SIZES} from './const'

type DetailsDescriptionProps = {
    title: number | String
    description: number | String
    fontSize?: FONTS_SIZES
}

export const DetailsDescription: React.FC<DetailsDescriptionProps> =
    ({title, description, fontSize = FONTS_SIZES.MEDIUM}) => {

        return (
            <Box textAlign='center'>
                <CustomTypography
                    component={FONTS_TAG_TYPE.H5}
                    type={FONTS.H5_BLACK_60}
                    value={title}
                />

                <CustomTypography
                    component={FONTS_TAG_TYPE.H3}
                    type={fontSize === FONTS_SIZES.BIG ? FONTS.H3_PINK_18 : FONTS.H3_PINK}
                    value={description}
                />
            </Box>
        )
    }
