import React, {SyntheticEvent, useState} from 'react'
import {Alert} from '@material-ui/lab'
import {Snackbar} from '@material-ui/core'
import {useStyles} from './styles'
import {defineErrorText} from './helper'

type ErrorToastType = {
    errorText: string
}


export const ErrorToast: React.FC<ErrorToastType> = ({errorText}) => {
    const [error, setError] = useState<string>(() => defineErrorText(errorText))
    const styles = useStyles()

    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setError('')
    }

    return (
        <Snackbar
            open={!!error.length}
            autoHideDuration={1500}
            onClose={handleClose}
        >
            <div dir='ltr'>
                <Alert onClose={handleClose} severity='error' variant='filled' className={styles.errorWrapper}>
                    <span className={styles.errorText}>{error}</span>
                </Alert>
            </div>
        </Snackbar>
    )
}

export default ErrorToast