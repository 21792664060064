import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    boxFooter: {
      position: 'fixed',
      width: '90%',
      left: '5%',
      zIndex: 100,
      bottom: 10,
      display: 'flex',
      justifyContent: 'center',
    },
  }
})
