import { makeStyles } from '@material-ui/core'
import { COLORS } from 'common/enums/colors'

export const useStyles = makeStyles(() => {
  return {
    divider: {
      color: COLORS.BLACK,
      backgroundColor: COLORS.WHITE,
      borderTop: '2px dotted lightgray',
    },
  }
})
