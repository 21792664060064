import {Box} from '@material-ui/core'
import {Wrapper} from '../Wrapper'
import {useStyles} from './styles'

type WhiteContainerProps = {
    children: React.ReactNode
    fullWidth?: boolean
    wrapper?: boolean
}

export const WhiteContainer: React.FC<WhiteContainerProps> = ({children, fullWidth, wrapper = true}) => {
    const styles = useStyles()

    return (
        <Box className={fullWidth ? styles.noPadding : styles.whiteBox}>
            {
                wrapper
                    ? <Wrapper>{children}</Wrapper>
                    : <Box>{children}</Box>
            }
        </Box>
    )
}
