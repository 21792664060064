import React, {ChangeEvent, Dispatch, SetStateAction, useContext} from 'react'
import {Box} from '@material-ui/core'
import {IdentifyingInfo} from '../IdentifyingInfo'
import {SignatureBox} from '../SignatureBox'
import {SwipableButton} from 'components/buttons/SwipableButton'
import {DETAILS} from '../../enums'
import ErrorToast from 'components/ToastNotifications/ErrorToast'
import {useStyles} from '../../styles'
import {UserDataType} from '../../index'
import {TeaserContext} from 'context/TeaserContext'
import {DEVICES_TYPES} from 'utils/gainDeviceType'
import {CustomButton} from 'components/buttons/Button'

type TeaserSignContentType = {
    userData: UserDataType
    handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | MouseEvent) => void
    syncSignature: Dispatch<SetStateAction<string>>
    isActive: boolean
    onSign: () => Promise<void>
    signError: string
    syncFieldError: Dispatch<SetStateAction<boolean>>
}

export const TeaserSignContent: React.FC<TeaserSignContentType> =
    ({userData, handleChange, onSign, signError, isActive, syncSignature, syncFieldError}) => {
        const {device} = useContext(TeaserContext)
        const styles = useStyles()

        return (
            <Box className={styles.container}>
                <Box>
                    <IdentifyingInfo
                        inputs={userData}
                        handleChange={handleChange}
                        syncFieldError={syncFieldError}
                    />
                    <SignatureBox syncSignature={syncSignature}/>
                </Box>
                <Box>
                    {device === DEVICES_TYPES.DESKTOP
                        ? <CustomButton title={DETAILS.SIGNATURE_PART} onSign={onSign} isActive={isActive}/>
                        : <SwipableButton title={DETAILS.SIGNATURE_PART} onSign={onSign} isActive={isActive}/>
                    }
                </Box>
                {signError && <ErrorToast errorText={signError}/>}
            </Box>
        )
    }