import { CustomTypography } from '../Typography'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { DESCRIPTION } from 'common/enums/texts'
import { WhiteContainer } from '../containers/WhiteContainer'
import { PropertyDescription } from '../PropertyDescription'
import { useEffect, useState } from 'react'
import { getDirections } from './helper'
import { AmenityItems } from './AmenityItems'
import { Box } from '@material-ui/core'
import { InstancesWrapper } from '../containers/InstancesWrapper'
import { useStyles } from './styles'

type PropertyCharacteristicsProps = {
  amenities?: string[]
  airDirections?: string[]
}

export const PropertyCharacteristics: React.FC<PropertyCharacteristicsProps> = ({ amenities, airDirections }) => {
  const [description, setDescription] = useState<string>('')
  const styles = useStyles()

  useEffect(() => {
    airDirections && setDescription(getDirections(airDirections))
  }, [airDirections])

  return (
    <WhiteContainer>
      <Box>
        <CustomTypography
          component={FONTS_TAG_TYPE.H3}
          type={FONTS.H3_BLACK}
          value={DESCRIPTION.PROPERTY_CHARACTERISTICS}
        />
      </Box>
      <InstancesWrapper>
        <AmenityItems amenities={amenities} />
        {description.length > 0 ? (
          <Box className={styles.airDirectionsWrapper}>
            <PropertyDescription title={DESCRIPTION.AIR_DIRECTIONS} description={description} gap />
          </Box>
        ) : (
          '-'
        )}
      </InstancesWrapper>
    </WhiteContainer>
  )
}
