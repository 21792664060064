import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    banner: {
      width: '100%',
      height: 203,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
    },
    accordionWrapper: {
      margin: '15px auto',
    },
    textWrapper: {
      margin: '1.5rem 0',
      textAlign: 'center',
    },
    title: {
      marginBottom: '1rem',
    },
    buttonWrapper: {
      position: 'absolute',
      bottom: 30,
      left: '5%',
      width: '90%',
      display: 'flex',
      justifyContent: 'center',
    },
    previewWrapper: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'white',
      zIndex: 999,
    },
    previewTopMargin: {
      marginTop: '70px',
    },
  }
})
