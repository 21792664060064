import { maxPosition, minSubmitValue, startPosition } from './const'
import { SwipeEventData } from 'react-swipeable'
import { Dispatch, SetStateAction } from 'react'

// eslint-disable-next-line
type onButtonActionType = {
  e: SwipeEventData
  setActiveStyles: Dispatch<SetStateAction<boolean>>
  setCirclePosition: Dispatch<SetStateAction<number>>
  onSign?: () => void
}

export const onButtonSwipe = ({ ...onButtonActionType }): void => {
  const { e, setActiveStyles, setCirclePosition } = onButtonActionType
  setActiveStyles(true)
  e.absX >= startPosition && e.dir === 'Left' && e.absX < maxPosition && setCirclePosition(e.absX)
}

export const onButtonSwiped = ({ ...onButtonActionType }): void => {
  const { e, setActiveStyles, setCirclePosition, onSign } = onButtonActionType

  setActiveStyles(false)
  if (e.absX < minSubmitValue) {
    setCirclePosition(startPosition)
  } else {
    setCirclePosition(minSubmitValue)
    onSign()
  }
}
