import { COLORS } from 'common/enums/colors'
import { makeStyles } from '@material-ui/core'
// import preloader from 'assets/images/preloader.gif'
import property from 'assets/images/property.png'

export const staticStyles = makeStyles(() => {
  return {
    mainBox: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      zIndex: -1,
    },
    mainBoxForDraft: {
      position: 'fixed',
      top: 55,
      right: 0,
      left: 0,
      zIndex: -1,
    },
    swiperWrapper: {
      maxWidth: '450px',
      margin: 'auto',
    },
    imageBox: {
      width: '100%',
      height: '100%',
    },
    image: {
      width: '100%',
      height: 350,
      overflow: 'hidden',
      objectFit: 'cover',
      // backgroundImage: `url(${preloader})`,
      // backgroundPosition: 'center',
      // backgroundRepeat: 'no-repeat',
    },
    bullet: {
      width: '6px',
      height: '6px',
      backgroundColor: COLORS.WHITE,
      opacity: 1,
      bottom: '70px',
    },
    bulletActive: {
      width: '30px',
      borderRadius: '10px',
    },
    emptyImage: {
      width: '100%',
      height: '350px',
      backgroundImage: `url(${property})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      transform: 'scale(1.05)',
    },
  }
})
