import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    box: {
      margin: '15px auto',
    },
    boxFooter: {
      margin: '50px 0 0',
    },
    separator: {
      marginBottom: 80,
    },
    propertyCard: {
      display: 'flex',
      padding: '12px 16px',
      minHeight: '48px',
      transition:
        ' min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      border: 0,
      margin: 0,
      color: 'inherit',
      outline: 0,
      position: 'relative',
      alignItems: 'center',
      userSelect: 'none',
      borderRadius: 0,
      verticalAlign: 'middle',
      justifyContent: 'center',
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
    propertyCardWrapper: {
      width: '100%',
      borderRadius: '16pt !important',
      position: 'relative',
      boxShadow: '0 2px 8px 0 rgb(0 0 0 / 12%)',
      transition: ' margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      backgroundColor: '#FFFFFF',
      margin: '15px auto',
    },
    previewWrapper: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'white',
      zIndex: 999,
    },
    previewTopMargin: {
      marginTop: '70px',
    },
  }
})
