import { FILES_TYPE } from 'common/enums/texts'
import pdf from 'assets/images/formats/pdf.svg'
import xlsx from 'assets/images/formats/xlsx.svg'
import docs from 'assets/images/formats/docs.svg'

export type ImageType = {
  alt: string
  image: string
}

type ImageDetailsType = {
  pdf: ImageType
  docx: ImageType
  xlsx: ImageType
}

export const imagesDetails: ImageDetailsType = {
  pdf: {
    alt: FILES_TYPE.PDF,
    image: pdf,
  },
  docx: {
    alt: FILES_TYPE.DOCX,
    image: docs,
  },
  xlsx: {
    alt: FILES_TYPE.XLSX,
    image: xlsx,
  },
}

export const LINK_TYPES = {
  BLANK: '_blank',
}
