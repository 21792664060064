import React, {useContext} from 'react'
import {Box} from '@material-ui/core'
import {ImageFormatsDetails} from '../../ImageFormatsDetails'
import {useStyles} from '../styles'
import {AttachmentsDetailsProps} from '../index'
import {TeaserContext} from 'context/TeaserContext'

export const Attachments: React.FC<AttachmentsDetailsProps> = ({attachments}) => {
    const {device} = useContext(TeaserContext)
    const styles = useStyles({device})

    return (
        <Box mt={2} className={styles.attachmentsWrapper}>
            <Box className={styles.attachments}>
                {attachments.map((link) =>
                    <ImageFormatsDetails
                        key={link.name}
                        link={link.url}
                        title={link.name}
                        extension={link.extension}
                    />
                )}
            </Box>
        </Box>
    )
}