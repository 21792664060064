import { makeStyles } from '@material-ui/core'
import { RADIUS } from 'common/enums/radius'

export const useStyles = makeStyles(() => {
  return {
    accordion: {
      borderRadius: RADIUS.ACCORDION_RADIUS,
    },
    box: {
      margin: '15px auto',
    },
  }
})
