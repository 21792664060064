export enum DETAILS {
  PROPERTY = 'פרטי הנכס',
  CONTRACT = 'פרטי החוזה',
  AGREEMENT = 'מספר הסכם:',
  BUYING_BROKERAGE = 'הסכם הזמנת שירותי תיווך לקניית נכס',
  RENTING_BROKERAGE = 'הסכם הזמנת שירותי תיווך להשכרת נכס',
  BOTH_BROKERAGE = 'הסכם הזמנת שירותי תיווך למכירת/השכרת נכס',
  OWNER_AGREEMENT_TITLE = 'הסכם הזמנת שירותי תיווך למכירת נכס',
  OWNER_EXCLUSIVE_AGREEMENT_TITLE = 'הסכם הזמנת שירותי תיווך למכירת נכס בבלעדיות',
  COLLABORATION_AGREEMENT_TITLE = 'הסכם שת"פ בין מתווכים',
  BROKERAGE_FEE_RATE = 'תעריף דמי התיווך',
  CLIENT_NAME = 'שם הלקוח',
  CLIENT_PASSPORT_NUMBER = 'ת.ז',
  CLIENT_ADDRESS = 'כתובת',
  CLIENT_PHONE_NUMBER = 'טלפון',
  SUBSCRIBE = 'מעבר לחתימה',
  USER_SIGNATURE = 'חתימת הלקוח',
  DATE_SIGNATURE = 'תאריך ושעה',
}

export enum AGREEMENT_TYPE {
  CLIENT = 'CLIENT',
  COLLABORATION = 'COLLABORATION',
  PROPERTY_OWNER = 'PROPERTY_OWNER',
}
