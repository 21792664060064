import { CoordinatesProps } from './Area'

const minRad = 0.0001
const maxRad = 0.001

const getRandomValue = (): number => parseFloat((Math.random() * (maxRad - minRad) + minRad).toFixed(6))

const getRandomAction = (): boolean => !!(Math.floor(Math.random() * (10 - 1) + 1) % 2)

export const getCirclePosition = (coordinates: CoordinatesProps): CoordinatesProps => {
  const randomLat = getRandomAction() ? coordinates.lat + getRandomValue() : coordinates.lat - getRandomValue()
  const randomLng = getRandomAction() ? coordinates.lng + getRandomValue() : coordinates.lng - getRandomValue()

  return { lat: parseFloat(randomLat.toFixed(6)), lng: parseFloat(randomLng.toFixed(6)) }
}
