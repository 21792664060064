export enum DEAL_DETAILS {
  SALE = 'מכירה',
  RENT = 'השכרה',
}

export enum DEAL_TYPE {
  RENT = 'rent',
}

export enum APARTMENT_DETAILS {
  FLOOR = 'קומה',
  ROOMS = 'חדרים',
  ROOMS_INSHORT = 'חד׳',
  SQUARE_BUILD = 'מ׳׳ר בנוי',
  SQUARE = 'מ׳׳ר',
  ELEVATOR = 'מעלית',
  BALCONY = 'מרפסת',
  TENANTS = 'ח. דיירים',
  PARKING = 'חנייה',
  FURNITURE = 'ריהוט',
  POOL = 'בריכה',
  REFUGE = 'ממ״ד',
  GRATINGS = 'סורגים',
  SUN_HEATER = 'דוד״ש',
  RENOVATED = 'משופץ',
  GUARD = 'שומר',
  TYPE_OF_APARTMENT = 'סוג נכס',
  PRICE_PER_METER = 'מחיר למ׳׳ר',
}

export enum DESCRIPTION {
  PROPERTY_DESCRIPTION = 'תיאור הנכס - טיזר',
  PROPERTY_CHARACTERISTICS = 'מאפייני הנכס',
  AIR_DIRECTIONS = 'כיווני אוויר',
  PAYMENTS = 'תשלומים נוספים',
  ARCH_PLANS = 'קבצים, תוכניות אדריכליות, נסח טאבו',
}

export enum FILES_TYPE {
  DOCX = 'docx',
  XLSX = 'xlsx',
  PDF = 'pdf',
}

export enum FILES {
  FORMATS = 'פורמט',
}

export enum USER_DETAILS {
  OWNER_DETAILS = 'פרטי בעל הנכס',
}

export enum AGENT_DETAILS {
  AGENT_DETAILS = 'פרטי המתווך',
}

export enum BUTTON_TITLES {
  EXCLUSIVELY = 'בבלעדיות',
}

export enum ADDITIONAL_PARAMS {
  UNTIL = 'עד ה',
}

export enum AGREEMENTS_DETAILS {
  AGREEMENTS_SIGNED = 'הסכמים שנחתמו',
  PENDING_SIGNING = 'בהמתנה לחתימה',
  TOTAL_AGREEMENTS = 'סה”כ הסכמים',
}
