import React from 'react'
import { Box } from '@material-ui/core'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { CustomTypography } from 'components/Typography'
import { useStyles } from './styles'

type HeaderDetailsProps = {
  address: string
  isExclusively: boolean
  date?: string
}

export const HeaderDetails: React.FC<HeaderDetailsProps> = ({ isExclusively, date, address }) => {
  const styles = useStyles()

  return (
    <Box dir="rtl" className={styles.headerWrapper}>
      <Box className={styles.headerText}>
        <CustomTypography component={FONTS_TAG_TYPE.H2} type={FONTS.H2_BLACK_80} value={address} />
      </Box>
      {/* {isExclusively && <Anchor date={date}/>} */}
    </Box>
  )
}
