import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useStyles } from '../../styles'
import { UserDataType } from '../../index'
import { Box } from '@material-ui/core'
import { CustomInput } from 'components/Input'
import { signatureUrl } from '../../const'

export type InputsBoxType = {
  inputs: UserDataType
  handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | MouseEvent) => void
  syncFieldError: Dispatch<SetStateAction<boolean>>
}

export const InputsBox: React.FC<InputsBoxType> = ({ inputs: propInputs, handleChange, syncFieldError }) => {
  const [inputs, setInputs] = useState<[string, string | boolean][]>(Object.entries(propInputs))
  const styles = useStyles()

  useEffect(() => {
    propInputs && setInputs(Object.entries(propInputs).filter(([name]) => name !== signatureUrl))
  }, [propInputs])

  return (
    <>
      {inputs.map(([name, value]) => (
        <Box key={name} className={styles.box}>
          <CustomInput
            isNumeric={name === 'signeeNationalId'}
            title={name}
            value={value}
            handleChange={handleChange}
            syncFieldError={syncFieldError}
          />
        </Box>
      ))}
    </>
  )
}
