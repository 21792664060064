import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    previewWrapper: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'white',
      zIndex: 999,
    },
    previewTopMargin: {
      marginTop: '70px',
    },
  }
})
