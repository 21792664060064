import {Box} from '@material-ui/core'
import {CustomTypography} from '../Typography'
import {FONTS, FONTS_TAG_TYPE} from 'common/enums/fonts'
import React from 'react'

type PropertyDescriptionProps = {
    title: string
    description: string
    gap?: boolean
}

export const PropertyDescription: React.FC<PropertyDescriptionProps> = ({title, description, gap}) => {
    return (
        <Box mb={1}>
            <Box mb={gap ? 2 : 1}>
                <CustomTypography
                    component={FONTS_TAG_TYPE.H3}
                    type={FONTS.H3_BLACK}
                    value={title}
                />
            </Box>
            <CustomTypography
                component={FONTS_TAG_TYPE.P}
                type={FONTS.P_Regular_Black_60}
                value={description}
            />
        </Box>
    )
}
