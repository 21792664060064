export enum PROPERTY_TYPES {
  APARTMENT = 'דירה',
  GARDEN_APARTMENT = 'דירת גן',
  PRIVATE_HOUSE = 'בית פרטי',
  ROOF_APPARTMENT = 'דירת גג',
  PENTHOUSE = 'פנטהאוז',
  TWO_FAMILY_DWELLING = 'דו משפחתי',
  DUPLEX = 'דופלקס',
  TRIPLEX = 'טריפלקס',
  PARTER = 'פרטר',
  BASEMENT = 'מרתף',
  LOFT_STUDIO = 'סטודיו/לופט',
  RESIDENTIAL_UNIT = 'יחידת דיור',
  STORAGE_UNIT = 'מחסן',
  PARKING_LOT = 'חניה',
  OFFICE = 'משרד',
  STORE = 'חנות',
  LOT = 'מגרש',
  BUILDING = 'בניין',
  ELDERLY_HOUSING = 'דיור מוגן',
  KEY_FEES = 'דמי מפתח',
  PURCHASE_GROUP = 'קבוצת רכישה',
  FARM_LAND = 'משק חקלא',
  ESTATE = 'נחלה',
  GENERAL = 'כללי',
}
