import React, {useEffect, useState} from 'react'
import {useSwipeable} from 'react-swipeable'
import {VscChevronLeft} from 'react-icons/vsc'
import {useStyles} from './styles'
import {startPosition} from './const'
import {onButtonSwipe, onButtonSwiped} from './helper'

type SwipableButtonProps = {
    title: string
    onSign: () => void
    isActive?: boolean
}

export const SwipableButton: React.FC<SwipableButtonProps> = ({title, onSign, isActive}) => {
    const [circlePosition, setCirclePosition] = useState<number>(5)
    const [activeStyles, setActiveStyles] = useState<boolean>(false)
    const styles = useStyles({isActive, circlePosition, startPosition})
    const [blockedStyles, setBlockedStyles] = useState<string>(styles.buttonWrapper)
    const [circleStyles, setCircleStyles] = useState<string>(styles.buttonSlider)
    const [circleIconStyles, setCircleIconStyles] = useState<string>(styles.buttonIcon)

    const handlers = useSwipeable({
        onSwiping: (e) => onButtonSwipe({e, setActiveStyles, setCirclePosition}),
        onSwiped: (e) => onButtonSwiped({e, setActiveStyles, setCirclePosition, onSign}),
        preventScrollOnSwipe: true,
        delta: startPosition,
    })

    useEffect(() => {
        setCircleStyles(activeStyles ? styles.buttonSlider + ' active' : styles.buttonSlider)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStyles])

    useEffect(() => {
        setBlockedStyles(isActive ? styles.buttonWrapper + ' unblocked' : styles.buttonWrapper)
        setCircleIconStyles(isActive ? styles.buttonIcon + ' active' : styles.buttonIcon)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive])

    return (
        <div className={blockedStyles}>
            <span className={styles.buttonText}>{title}</span>
            <div {...handlers} className={circleStyles}>
                <span className={circleIconStyles}>
                    <VscChevronLeft/>
                </span>
            </div>
        </div>
    )
}