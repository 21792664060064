import { Dispatch, SetStateAction } from 'react'

const setData = (setWebViewData: Dispatch<SetStateAction<string | undefined>>): void => {
  setTimeout(() => {
    window.flutter_inappwebview.callHandler('webViewHandler').then((stuff: string) => {
      setWebViewData(JSON.stringify(stuff))
    })
  }, 0)
}

export const handleWebViewData = (
  webViewData: string | undefined,
  setWebViewData: Dispatch<SetStateAction<string | undefined>>,
): void => {
  window.addEventListener('flutterInAppWebViewPlatformReady', () => {
    setData(setWebViewData)
  })
  setData(setWebViewData)
}

export const cleanWebViewData = (): void => {
  window.removeEventListener('flutterInAppWebViewPlatformReady', () => {})
}
