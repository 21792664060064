import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchTeaser, fetchTeaserPreview } from 'services/teaser'
import { TeaserContext } from 'context/TeaserContext'
import { PREVIEW_TYPE } from './../TeaserScreen/const'
import { handleWebViewData } from 'utils/handleWebViewData'
import { checkType } from './../TeaserScreen/helper'
import { TeaserScreenBodySigned } from './components/TeaserScreenBodySigned'

export const TeaserScreenSigned = () => {
  const { gainTeaser, teaser } = useContext(TeaserContext)
  const { teaserId } = useParams()
  const [error, setError] = useState<string>('')
  const [previewType, setPreviewType] = useState<PREVIEW_TYPE>(PREVIEW_TYPE.UNSET)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [webViewData, setWebViewData] = useState<undefined | string>(undefined)

  const fetchHelper = async (isWebView: boolean) => {
    if (!teaserId) return
    setIsLoading(true) // show loading on API call
    const data =
      isWebView && webViewData ? await fetchTeaserPreview(webViewData, teaserId) : await fetchTeaser(teaserId)
    if ('error' in data) {
      setError(data?.error)
    } else if (checkType(data)) {
      setPreviewType(data.preview)
      gainTeaser(data.data)
    } else {
      gainTeaser(data)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    handleWebViewData(webViewData, setWebViewData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!teaser) {
      // if there's already a teaser, dont fetch it again and show loader
      fetchHelper(!!webViewData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webViewData])

  return (
    <>
      <TeaserScreenBodySigned
        error={error}
        previewType={previewType}
        webViewData={!!webViewData}
        isLoading={isLoading}
      />
    </>
  )
}
