import { makeStyles } from '@material-ui/core'
import { COLORS } from 'common/enums/colors'

type SliderButtonProps = {
  isActive?: boolean
  circlePosition: number
  startPosition: number
}

export const useStyles = makeStyles(() => {
  return {
    buttonWrapper: {
      position: 'relative',
      marginTop: 20,
      width: '100%',
      height: 70,
      fontFamily: 'Noto Sans Hebrew, Helvetica, Noto Sans, Roboto, Arial, sans-serif !important',
      display: 'flex',
      justifyContent: 'center',
      border: 'white',
      borderRadius: 40,
      transition: 'background .5s',
      background: 'lightgray',
      '&.unblocked': {
        backgroundColor: COLORS.PINK,
      },
    },
    buttonText: {
      color: 'white',
      fontSize: 16,
      fontWeight: 'bold',
      width: 'inherit',
      height: 'inherit',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonSlider: {
      position: 'absolute',
      right: ({ isActive, circlePosition, startPosition }: SliderButtonProps) =>
        isActive ? circlePosition : startPosition,
      top: 5,
      width: 60,
      height: 60,
      borderRadius: '50%',
      backgroundColor: 'white',
      border: 'none',
      transition: 'all .25s',
      '&.active': {
        transition: 'none',
      },
      '&.unlocked': {
        transition: 'inherit',
        background: COLORS.PINK_LIGHT,
        right: 5,
        top: 5,
      },
    },
    buttonIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 60,
      height: 60,
      fontSize: '2.5rem',
      color: 'lightgray',
      transition: 'all .25s',
      '&.active': {
        color: COLORS.PINK,
      },
    },
  }
})
