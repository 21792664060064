import email from 'assets/images/contacts/message.svg'
import whatsapp from 'assets/images/contacts/whatsapp.svg'
import phone from 'assets/images/contacts/call.svg'

export const getIconPath = (icon: string): string => {
  let path: string
  if (icon === 'email') path = email
  else if (icon === 'whatsapp') path = whatsapp
  else path = phone
  return path
}
