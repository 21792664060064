import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    imagesWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '33.33%',
      wordBreak: 'break-all',
      textAlign: 'center',
    },
  }
})
