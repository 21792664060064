import React from 'react'
import {Box} from '@material-ui/core'
import {ImageButton} from '../buttons/ImageButton'
import {useStyles} from './styles'
import {CustomTypography} from '../Typography'
import {FONTS} from 'common/enums/fonts'
import {fileNameMaxLength} from './const'

type ImageFormatsDetailsProps = {
    link: string
    title: string
    extension: string
}

export const ImageFormatsDetails: React.FC<ImageFormatsDetailsProps> = ({link, title, extension}) => {
    const styles = useStyles()

    return (
        <Box mb={1} className={styles.imagesWrapper}>
            <ImageButton type={extension} link={link}/>
            <CustomTypography
                value={title.length > fileNameMaxLength ? '...' + title.slice(0, fileNameMaxLength) : title}
                component='h5'
                type={FONTS.H5_BLACK_60}
            />
        </Box>
    )
}
