import React from 'react'
import { Box } from '@material-ui/core'
import { staticStyles } from './styles'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper'

export type ImageProps = {
  imageUrl: string
  thumbUrl: string
  order?: number
}

type ImageSliderProps = {
  images?: ImageProps[]
  isDraft: boolean
}

export const ImageSlider: React.FC<ImageSliderProps> = ({ images, isDraft }) => {
  const styles = staticStyles()
  images?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))

  return (
    <Box className={isDraft ? styles.mainBoxForDraft : styles.mainBox}>
      <Box className={styles.swiperWrapper}>
        <Swiper
          pagination={{
            clickable: true,
            bulletClass: `swiper-pagination-bullet swiper-pagination-horizontal ${styles.bullet}`,
            bulletActiveClass: `swiper-pagination-bullet-active ${styles.bulletActive}`,
          }}
          modules={[Pagination]}
          loop={images && images.length > 1}
        >
          {images?.length ? (
            images.map(({ imageUrl }: ImageProps) => (
              <SwiperSlide key={imageUrl}>
                <img className={styles.image} src={imageUrl} alt={imageUrl} />
              </SwiperSlide>
            ))
          ) : (
            <div className={styles.emptyImage} />
          )}
        </Swiper>
      </Box>
    </Box>
  )
}
