import React, { useContext, useEffect, useState } from 'react'
import { Wrapper } from 'components/containers/Wrapper'
import { Box, Grid } from '@material-ui/core'
import { useStyles } from './styles'
import { ContactsContainerDetails } from '../TeaserProperty/components/ContactsContainerDetails'
import { ContractInfo } from 'components/ContractInfo'
import handshake from 'assets/images/mock.png'
import { ConfirmationTitle } from './ConfirmationTitle'
import { TeaserContext } from 'context/TeaserContext'
import { useNavigate, useParams } from 'react-router-dom'
import { transformSignDate } from '../TeaserScreen/components/TeaserSignatureDetails/helper'
import { IPerson } from 'interfaces/teaser.interface'
import { DATE_TYPES } from '../TeaserScreen/components/TeaserSignatureDetails/const'
import { TeaserPreviewHandler } from 'pages/TeaserScreen/components/TeaserPreviewHandler'
import { AGREEMENT_TYPE } from 'pages/TeaserScreen/enums'
import { IAddress } from 'interfaces/teaser.interface'

export const TeaserConfirmation = () => {
  const { teaser } = useContext(TeaserContext)
  const navigate = useNavigate()
  const { teaserId } = useParams()
  const classes = useStyles()
  const [signedDate, setSignedDate] = useState<string>('')
  const [agentData, setAgentData] = useState<IPerson | undefined>(undefined)

  useEffect(() => {
    if (!teaser || !teaser.properties.length) {
      navigate(`/teasers/${teaserId}`)
      return
    }

    if (teaser.showPropertyFullProfile && teaser.agreement.type !== AGREEMENT_TYPE.PROPERTY_OWNER) {
      navigate(`/teasers/${teaserId}/properties/${teaser.properties[0].id}`)
      return
    }

    setSignedDate(transformSignDate(teaser.agreement.signedAt, DATE_TYPES.PROPERTY))
    setAgentData(teaser.agent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teaser])

  const formatAddress = (address: IAddress) => {
    const { street, apartment, number, city } = address
    if (apartment) {
      return `${street} ${number}/${apartment}, ${city}`
    } else {
      return `${street} ${number}, ${city}`
    }
  }

  const getContractInfoText = () => {
    if (teaser?.properties.length === 1) {
      return formatAddress(teaser?.properties[0].address)
    }

    return `הסכם זה מכיל ${teaser?.properties.length} נכסים`
  }

  return (
    <>
      {teaser?.isDraft && (
        <Box className={classes.previewWrapper}>
          <TeaserPreviewHandler />
        </Box>
      )}
      <Wrapper>
        <Box className={teaser?.isDraft ? classes.previewTopMargin : ''}>
          <Box className={classes.banner}>
            <img src={handshake} alt="handshake" />
          </Box>
          <ConfirmationTitle role={teaser?.agreement.type || ''} />
          <Box>
            <ContractInfo
              extraMargin
              link={teaser?.agreement.fileUrl || ''}
              date={signedDate}
              text={getContractInfoText()}
            />
          </Box>
          <Box>
            {agentData && (
              <Grid item xs={12}>
                <ContactsContainerDetails
                  name={agentData.fullName}
                  image={agentData.image?.imageUrl || ''}
                  description={agentData.description}
                  phone={agentData.phoneNumber}
                />
              </Grid>
            )}
          </Box>
        </Box>
      </Wrapper>
    </>
  )
}
