import { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { useStyles } from '../../styles'
import { CustomTypography } from 'components/Typography'
import { AGREEMENT_TYPE, DETAILS } from '../../enums'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { TeaserBrokerageDetailsType } from '../TeaserBrokerageDetails'

interface OrderDetailsProps extends TeaserBrokerageDetailsType {
  agreementId: number
  agreementType: string
  exclusivity?: boolean
  isDraft: boolean
}

export const TeaserOrderDetails: React.FC<OrderDetailsProps> = ({
  agreementId,
  agreementType,
  sellCommission,
  rentCommission,
  exclusivity,
  isDraft,
}) => {
  const { sum: sumS, percentage: percentageS } = sellCommission
  const { sum: sumR, percentage: percentageR } = rentCommission
  const [contractType, setContractType] = useState<string>('')

  useEffect(() => {
    setContractType(() => {
      if (agreementType === AGREEMENT_TYPE.PROPERTY_OWNER && exclusivity) return DETAILS.OWNER_AGREEMENT_TITLE
      if (agreementType === AGREEMENT_TYPE.PROPERTY_OWNER) return DETAILS.OWNER_AGREEMENT_TITLE
      if (agreementType === AGREEMENT_TYPE.COLLABORATION) return DETAILS.COLLABORATION_AGREEMENT_TITLE
      if ((sumR || percentageR) && (sumS || percentageS)) return DETAILS.BOTH_BROKERAGE
      else if (sumR || percentageR) return DETAILS.RENTING_BROKERAGE
      else return DETAILS.BUYING_BROKERAGE
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const styles = useStyles()

  return (
    <Box className={styles.box}>
      <CustomTypography component={FONTS_TAG_TYPE.H2} type={FONTS.H2_BLACK_18} value={contractType} />
      {isDraft ? null : (
        <CustomTypography
          component={FONTS_TAG_TYPE.H4}
          type={FONTS.H4_PINK_60}
          value={`${DETAILS.AGREEMENT} ${agreementId}`}
        />
      )}
    </Box>
  )
}
