import { COLORS } from '../enums/colors'
import { RADIUS } from '../enums/radius'

export const drawerTheme = () => ({
  MuiDrawer: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0) !important',
    },
    paper: {
      borderRadius: RADIUS.PAPER_RADIUS,
      backgroundColor: COLORS.WHITE,
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'none',
    },
  },
})
