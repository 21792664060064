import React, {useEffect, useState} from 'react'
import {CustomMarker} from '../Marker'
import {CoordinatesProps, CustomArea} from '../Area'
import {getCirclePosition} from '../helper'

type AddressProps = {
    coordinates: CoordinatesProps
    showFullAddress: boolean
}

export const Address: React.FC<AddressProps> = ({coordinates, showFullAddress}) => {
    const getInitCoords = () => showFullAddress ? coordinates : getCirclePosition(coordinates)

    const [customCoordinates, setCustomCoordinates] = useState(getInitCoords)

    useEffect(() => {
        setCustomCoordinates(showFullAddress ? coordinates : getCirclePosition(coordinates))
    }, [coordinates, showFullAddress])

    return showFullAddress
        ? <CustomMarker lat={customCoordinates.lat} lng={customCoordinates.lng}/>
        : <CustomArea lat={customCoordinates.lat} lng={customCoordinates.lng}/>
}