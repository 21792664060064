import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    signature: {
      width: '100%',
      textAlign: 'center',
      margin: '10px auto',
    },
  }
})
