import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    mainBox: {
      position: 'relative',
      marginTop: '320px',
      paddingTop: '40px',
      borderRadius: '30px 30px 0 0',
      backgroundColor: 'white',
    },
    mainBoxForDraft: {
      position: 'relative',
      marginTop: '375px',
      paddingTop: '40px',
      borderRadius: '30px 30px 0 0',
      backgroundColor: 'white',
    },
  }
})
