import React from 'react'
import {Button} from '@material-ui/core'
import {useStyles} from './styles'

type CustomButtonProps = {
    title: string
    onSign: () => void
    isActive?: boolean
}

export const CustomButton: React.FC<CustomButtonProps> = ({title, onSign, isActive = true}) => {
    const styles = useStyles()

    return (
        <Button disabled={!isActive} onClick={onSign} variant='contained' className={isActive ? styles.button : styles.disabled}>
            {title}
        </Button>
    )
}