import { LINK_TYPES } from './const'
import { useStyles } from './styles'
import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { getImagePath } from './helper'

type ImageButtonProps = {
  type: string | undefined
  link: string
}

export const ImageButton: React.FC<ImageButtonProps> = ({ type, link }) => {
  const [imagePath, setImagePath] = useState<string | undefined>()
  const styles = useStyles()

  useEffect(() => {
    setImagePath(getImagePath(type))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={styles.box}>
      <a href={link} target={LINK_TYPES.BLANK} download>
        <img className={styles.image} alt={type} src={imagePath} />
      </a>
    </Box>
  )
}
