import React, { useContext } from 'react'
import { TeaserPreviewHandler } from '../TeaserPreviewHandler'
import { Wrapper } from 'components/containers/Wrapper'
import { Box } from '@material-ui/core'
import { PREVIEW_TYPE } from '../../const'
import { TeaserPropertyDetails } from '../TeaserPropertyDetails'
import { TeaserFooterDetails } from '../TeaserFooterDetails'
import { useStyles } from '../../styles'
import { TeaserContext } from 'context/TeaserContext'
import Error from 'pages/Error/Error'
import { TeaserAgentDetails } from './TeaserAgentDetails'
import { TeaserAgreements } from './TeaserAgreements'
import { useNavigate } from 'react-router-dom'

type TeaserScreenBodyType = {
  webViewData: boolean
  previewType: PREVIEW_TYPE
  error: string
  isLoading: boolean
}

export const TeaserScreenBody: React.FC<TeaserScreenBodyType> = ({ webViewData, previewType, isLoading, error }) => {
  const { teaser } = useContext(TeaserContext)
  const styles = useStyles()
  const navigate = useNavigate()

  if (error.length && !teaser && !webViewData && !isLoading) return <Error message={error} />
  if (!teaser || isLoading) return <></>

  if (teaser?.agreement.signed) {
    if (teaser?.showPropertyFullProfile) {
      navigate(`/teasers/${teaser.id}/properties/${teaser.properties[0].id}`)
    } else {
      navigate(`/teasers/${teaser.id}/sign/success`)
    }

    return <></>
  }

  return (
    <>
      {teaser?.isDraft && (
        <Box className={styles.previewWrapper}>
          <TeaserPreviewHandler />
        </Box>
      )}
      <Wrapper>
        <Box className={teaser?.isDraft ? styles.previewTopMargin : ''}>
          <TeaserAgentDetails teaser={teaser} />

          <TeaserPropertyDetails
            isSigned={teaser.agreement.signed}
            properties={teaser.properties}
            teaserId={teaser.id}
            showFullAddress={teaser.showFullAddress}
            showPropertyFullProfile={teaser.showPropertyFullProfile}
            previewType={previewType}
            agreementType={teaser.agreement.type}
          />

          <TeaserAgreements teaser={teaser} />

          {!teaser.isDraft && (
            <TeaserFooterDetails
              isSigned={teaser.agreement.signed}
              signedAt={teaser.agreement.signedAt}
              signatureUrl={teaser.agreement.signatureUrl}
            />
          )}

          {!teaser.agreement.signed && !webViewData && <Box className={styles.separator} />}
        </Box>
      </Wrapper>
    </>
  )
}
