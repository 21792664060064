export enum COLORS {
  WHITE = '#FFFFFF',
  BLACK = '#000000',
  BLACK_LIGHT = '#1A0000',
  PINK = '#FA065B',
  PINK_LIGHT = '#FF335B3D',
  GREY = '#1A00004D',
  GREY_LIGHT = '#F5F4F4',
  GREY_DARK = '#1A000099',
}
