import React from 'react'
import './preloader.scss'
import preloader from 'assets/images/preloader.gif'

export const Preloader = () => {
    return (
        <div className='preloader-container'>
            <img src={preloader} width={200} height={200} alt='Preloader'/>
        </div>
    )
}