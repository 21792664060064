export const typographyTheme = () => ({
  MuiTypography: {
    root: {
      fontFamily: 'Noto Sans Hebrew, Helvetica, Noto Sans, Roboto, Arial, sans-serif !important',
    },
    h1: {
      fontSize: 24,
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
    },
    h3: {
      fontSize: 16,
    },
    h4: {
      fontSize: 14,
    },
    h5: {
      fontSize: 12,
    },
    h6: {
      fontSize: 10,
    },
    subtitle1: {
      fontSize: 14,
    },
    subtitle2: {
      fontSize: 35,
    },
  },
})
