import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    flexBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'space-between',
    },
    opacityBox: {
      opacity: '40%',
    },
    reversed: {
      flexDirection: 'row-reverse',
    },
  }
})
