import './error.scss'
import {ErrorInfo} from 'react'
import {House} from 'components/House/House'

interface ErrorProps {
    message: string | ErrorInfo | null
}

export default function Error(props: ErrorProps) {
    const {message} = props

    return (
        <div className='teaser-error'>
            <House/>
            <p className='title'>
                {message ? `Error: ${message}` : 'Unknown Error'}
            </p>
        </div>
    )
}
