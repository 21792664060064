import {AccordionDetails, Box} from '@material-ui/core'
import {DESCRIPTION} from 'common/enums/texts'
import {PropertyDescription} from '../../PropertyDescription'
import {CustomAccordionProps} from '../index'
import {Map} from '../../Map'
import {useStyles} from './styles'

export const CustomAccordionDetails: React.FC<CustomAccordionProps> = ({property, showFullAddress}) => {
    const {description, address} = property
    const styles = useStyles()
    return (
        <AccordionDetails>
            <Box className={styles.wrapper}>
                {
                    description && <PropertyDescription
                        title={DESCRIPTION.PROPERTY_DESCRIPTION}
                        description={description}
                    />
                }
                <Map showFullAddress={showFullAddress} address={address}/>
            </Box>
        </AccordionDetails>
    )
}
