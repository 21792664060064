import React, {useEffect, useState} from 'react'
import {Box} from '@material-ui/core'
import {CustomTypography} from 'components/Typography'
import {FONTS, FONTS_TAG_TYPE} from 'common/enums/fonts'
import {ROLES, ROLES_TEXT, title} from '../const'
import {useStyles} from '../styles'

type ConfirmationTitleType = {
    role: string
}

export const ConfirmationTitle: React.FC<ConfirmationTitleType> = ({role}) => {
    const classes = useStyles()
    const [descriptionText, setDescriptionText] = useState<string>('')

    useEffect(() => {
        setDescriptionText(() => {
            switch (role) {
                case ROLES.CLIENT:
                    return ROLES_TEXT.CLIENT_TEXT
                case ROLES.COLLABORATION:
                    return ROLES_TEXT.COLLABORATION_TEXT
                default:
                    return ROLES_TEXT.DEFAULT
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box className={classes.textWrapper}>
            <Box className={classes.title}>
                <CustomTypography
                    component={FONTS_TAG_TYPE.H3}
                    type={FONTS.H3_PINK}
                    value={title}
                />
            </Box>
            <Box>
                <CustomTypography
                    component={FONTS_TAG_TYPE.H3}
                    type={FONTS.P_Regular_Black_60}
                    value={descriptionText}
                />
            </Box>
        </Box>
    )
}