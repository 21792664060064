export enum DEVICES_TYPES {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export const gainDeviceType = (isWebView: string): string => {
  if (isWebView) {
    return 'webView'
  }
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return DEVICES_TYPES.TABLET
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    return DEVICES_TYPES.MOBILE
  }
  return DEVICES_TYPES.DESKTOP
}
