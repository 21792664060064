import { makeStyles } from '@material-ui/core'
import { RADIUS } from 'common/enums/radius'

export const useStyles = makeStyles(() => {
  return {
    box: {
      overflow: 'hidden',
      width: '100%',
      height: 300,
      borderRadius: RADIUS.ACCORDION_RADIUS,
      boxShadow: '0 2px 8px 0 rgb(0 0 0 / 12%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'translateZ(0)',
      isolation: 'isolate',
    },
  }
})
