import {Box} from '@material-ui/core'
import {useStyles} from './styles'
import {ReactNode} from 'react'

type WrapperProps = {
    children: ReactNode
}

export const Wrapper: React.FC<WrapperProps> = ({children}) => {
    const styles = useStyles()

    return <Box className={styles.screen}>{children}</Box>
}
