import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    errorWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '5px 0',
      '&.MuiAlert-icon': {
        fontSize: '2rem',
      },
    },
    errorText: {
      margin: '0 10px',
    },
  }
})
