import React from 'react'

type IconLinkType = {
  url?: string | undefined
  title: string
  icon: string
}

export const IconLink: React.FC<IconLinkType> = ({ url, icon, title }) => (
  <a href={url} rel="noreferrer" target="_blank">
    <img alt={title} src={icon} />
  </a>
)
