import React, {Dispatch, SetStateAction, useRef} from 'react'
import {Box} from '@material-ui/core'
import {useStyles} from '../../styles'
import {Signature} from 'components/Signature'
import {SignatureHeader} from '../SignatureHeader'

type SignatureBoxType = {
    syncSignature: Dispatch<SetStateAction<string>>
}

export const SignatureBox: React.FC<SignatureBoxType> = ({syncSignature}) => {
    const signatureRef = useRef<any>(null)
    const styles = useStyles()

    const onReset = () => {
        if (!signatureRef?.current) {
            return
        }
        syncSignature('')
        signatureRef.current.clear()
    }

    return (
        <Box className={styles.wrapper}>
            <Box className={styles.textWrapper}>
                <SignatureHeader onReset={onReset}/>
            </Box>
            <Box className={styles.flexBox}>
                <Signature syncSignature={syncSignature} signatureRef={signatureRef}/>
            </Box>
        </Box>
    )
}
