import { useState } from 'react'
import { AccordionSummary, Grid } from '@material-ui/core'
import { KeyboardArrowDownTwoTone } from '@material-ui/icons'
import { CustomTypography } from '../../Typography'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { CustomAccordionProps } from '../index'
import { DETAILS_TYPES } from './const'
import { DEAL_DETAILS } from 'common/enums/texts'
import { getCurrentPrice } from '../helper'

export const CustomAccordionSummary: React.FC<CustomAccordionProps> = ({ property, showFullAddress }) => {
  const {
    address: { street, apartment, city, number },
    priceRent,
    priceSell,
  } = property

  const detailsHelper = (detailsType: DETAILS_TYPES): string => {
    switch (detailsType) {
      case DETAILS_TYPES.RENT:
        return priceRent ? `${DEAL_DETAILS.RENT} | ${getCurrentPrice(priceRent)}` : ''
      case DETAILS_TYPES.SELL:
        return priceSell ? `${DEAL_DETAILS.SALE} | ${getCurrentPrice(priceSell)}` : ''
      case DETAILS_TYPES.ADDRESS:
        if (!showFullAddress) {
          return `${street}, ${city}`
        } else if (apartment) {
          return `${street} ${number}/${apartment}, ${city}`
        } else {
          return `${street} ${number}, ${city}`
        }
      default:
        return ''
    }
  }

  const [addressDetails] = useState<string>(() => detailsHelper(DETAILS_TYPES.ADDRESS))
  const [rentDetails] = useState<string>(() => detailsHelper(DETAILS_TYPES.RENT))
  const [sellDetails] = useState<string>(() => detailsHelper(DETAILS_TYPES.SELL))

  return (
    <AccordionSummary expandIcon={<KeyboardArrowDownTwoTone className="accordion_icon" />}>
      <Grid item xs={12}>
        <CustomTypography component={FONTS_TAG_TYPE.H3} type={FONTS.H3_BLACK} value={addressDetails} />
        {rentDetails && <CustomTypography component={FONTS_TAG_TYPE.H4} type={FONTS.H4_PINK_60} value={rentDetails} />}
        {sellDetails && <CustomTypography component={FONTS_TAG_TYPE.H4} type={FONTS.H4_PINK_60} value={sellDetails} />}
      </Grid>
    </AccordionSummary>
  )
}
