import { COLORS } from '../enums/colors'
import { SHADOW } from '../enums/shadow'

export const accordionTheme = () => ({
  MuiAccordion: {
    root: {
      width: '100%',
      backgroundColor: COLORS.WHITE,
      boxShadow: SHADOW.SHADOW_BOX,
    },
  },
})
