import React, { useEffect, useMemo, useState } from 'react'
import { useStyles } from './styles'
import { useNavigate } from 'react-router-dom'
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from 'react-icons/md'
import { IData } from 'pages/TeaserScreen/types'
import { firstIndex, PAGINATION_DIRECTIONS } from './const'

type PaginationButtonProps = {
  teaser: IData
  id: string
}

export const PaginationButton: React.FC<PaginationButtonProps> = ({ teaser, id }) => {
  const navigate = useNavigate()
  const styles = useStyles()
  const currentProperty = useMemo(() => teaser.properties.find(prop => prop.id === Number.parseInt(id)), [id, teaser])
  const propertiesIds = useMemo(() => teaser.properties.map(property => property.id), [teaser])
  const lastIndex = useMemo(() => propertiesIds.length, [propertiesIds])
  const definePropertyIndex = useMemo(
    () => (!currentProperty ? firstIndex : propertiesIds.indexOf(currentProperty?.id)),
    [currentProperty, propertiesIds],
  )

  const [currentPropertyIndex, setCurrentPropertyIndex] = useState<number>(definePropertyIndex)

  const goForward = (): number => {
    if (currentPropertyIndex + 1 >= lastIndex) {
      return propertiesIds[firstIndex]
    }
    return propertiesIds[currentPropertyIndex + 1]
  }
  const goBackward = (): number => {
    if (currentPropertyIndex - 1 < 0) {
      return propertiesIds[lastIndex - 1]
    }
    return propertiesIds[currentPropertyIndex - 1]
  }

  const switchProperty = (dir: string): void =>
    navigate(`/teasers/${teaser.id}/properties/${dir === PAGINATION_DIRECTIONS.NEXT ? goForward() : goBackward()}`)

  useEffect(() => {
    if (!currentProperty) {
      return
    }
    setCurrentPropertyIndex(propertiesIds.indexOf(currentProperty.id))
  }, [currentProperty, propertiesIds])

  return (
    <div className={styles.paginationButton}>
      <MdOutlineNavigateNext onClick={() => switchProperty(PAGINATION_DIRECTIONS.NEXT)} className={styles.icon} />
      <span className={styles.buttonText}>{currentPropertyIndex + 1 + '/' + lastIndex}</span>
      <MdOutlineNavigateBefore onClick={() => switchProperty(PAGINATION_DIRECTIONS.PREV)} className={styles.icon} />
    </div>
  )
}
