import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    paginationButton: {
      position: 'fixed',
      left: 'calc(50% - 100px)',
      bottom: 20,
      textAlign: 'center',
      color: 'white',
      background: 'rgba(0, 0, 0, 0.4)',
      zIndex: 9,
      width: 200,
      height: 40,
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    buttonText: {
      fontWeight: 700,
      fontSize: '1.2rem',
      flex: 1,
    },
    icon: {
      fill: 'white !important',
      height: 35,
      width: 35,
    },
  }
})
