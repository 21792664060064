import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { DEAL_DETAILS } from 'common/enums/texts'
import { getCurrentPrice } from 'components/Accordion/helper'
import { WhiteContainer } from 'components/containers/WhiteContainer'
import { ApartmentDetails } from '../ApartamentDetails'
import { DealDetails } from '../DealDetails'
import { useStyles } from './styles'
import { PropertyInterface } from 'interfaces/teaser.interface'
import { getPropertyType } from './helper'

type ApartmentDescriptionProps = {
  estateObject: PropertyInterface
}

export const ApartmentDescription: React.FC<ApartmentDescriptionProps> = ({ estateObject }) => {
  const {
    type: unconvertedType,
    priceRent,
    priceRentPerMeter,
    priceSell,
    priceSellPerMeter,
    squareFeet,
    floor,
    buildingTotalFloors,
    rooms,
  } = estateObject
  const styles = useStyles()
  const [type, setType] = useState<string | undefined>(undefined)

  useEffect(() => {
    setType(getPropertyType(unconvertedType))
  }, [unconvertedType])

  return (
    <Grid container spacing={2} className={styles.reversed}>
      <Grid container item xs={8} className={styles.flexBox}>
        <Grid item xs={12}>
          <WhiteContainer fullWidth>
            <Box className={priceSell ? '' : styles.opacityBox}>
              <DealDetails
                type={DEAL_DETAILS.SALE}
                count={getCurrentPrice(priceSell || 0)}
                description={getCurrentPrice(priceSellPerMeter || 0)}
              />
            </Box>
          </WhiteContainer>
        </Grid>

        <Grid item xs={12}>
          <WhiteContainer fullWidth>
            <Box className={priceRent ? '' : styles.opacityBox}>
              <DealDetails
                type={DEAL_DETAILS.RENT}
                count={getCurrentPrice(priceRent || 0)}
                description={getCurrentPrice(priceRentPerMeter || 0)}
              />
            </Box>
          </WhiteContainer>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <WhiteContainer fullWidth wrapper={false}>
          <ApartmentDetails
            floor={floor}
            type={type}
            meters={squareFeet}
            rooms={rooms}
            totalFloors={buildingTotalFloors}
          />
        </WhiteContainer>
      </Grid>
    </Grid>
  )
}
