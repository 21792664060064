import React, { useState } from 'react'
import { initialState, WINDOW_TYPES } from './const'
import { IData } from 'pages/TeaserScreen/types'
import { gainDeviceType } from 'utils/gainDeviceType'
import { useNavigate } from 'react-router-dom'
import { AGREEMENT_TYPE } from 'pages/TeaserScreen/enums'

type TeaserContextWrapperType = {
  children: React.ReactNode
}

export const TeaserContext = React.createContext(initialState)

export const TeaserContextWrapper: React.FC<TeaserContextWrapperType> = ({ children }) => {
  const navigate = useNavigate()
  const [teaser, setTeaser] = useState<IData | undefined>(undefined)
  const [device, setDevice] = useState<string>('')
  const [activeWindow, setActiveWindow] = useState<WINDOW_TYPES>(WINDOW_TYPES.UNSIGNED)

  const gainTeaser = (teaserData: IData): void => {
    const userDevice = gainDeviceType('')
    setDevice(userDevice)
    setTeaser(teaserData)
  }

  const switchPreviewWindow = (windowType: WINDOW_TYPES, teaser: IData | undefined): void => {
    setActiveWindow(windowType)
    const navigateTo = whichPreview(windowType, teaser)
    navigate(navigateTo)
  }

  const whichPreview = (windowType: WINDOW_TYPES, teaser: IData | undefined): string => {
    let route = ''
    if (windowType === WINDOW_TYPES.SIGNED) {
      // means it signed
      if (teaser?.agreement.type !== AGREEMENT_TYPE.PROPERTY_OWNER) {
        // all except owners
        if (teaser?.showPropertyFullProfile) {
          // only if agent set that he want to show the profile
          route = `/teasers/${teaser?.id}/properties/${teaser?.properties[0].id}`
        } else {
          // else show success
          route = `/teasers/${teaser?.id}/sign/success`
        }
      } else {
        // clients & agents
        route = `/teasers/${teaser.id}/sign/success`
      }
    } else {
      // unsigned
      route = `/teasers/${teaser?.id}`
    }
    return route
  }

  return (
    <TeaserContext.Provider value={{ teaser, device, activeWindow, gainTeaser, switchPreviewWindow }}>
      {children}
    </TeaserContext.Provider>
  )
}
