import { PROPERTY_TYPES } from './const'

export const getPropertyType = (type?: string): PROPERTY_TYPES => {
  if (!type) return PROPERTY_TYPES.APARTMENT
  const keys = Object.keys(PROPERTY_TYPES)
  const vals = Object.values(PROPERTY_TYPES)

  let res: PROPERTY_TYPES = PROPERTY_TYPES.APARTMENT

  keys.forEach((key, i) => {
    if (type === key) res = vals[i]
  })

  return res
}
