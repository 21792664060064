import React from 'react'
import './house.scss'

export const House = () => {
    return (
        <div className='house-wrapper'>
            <div className='building'>
                <div className='window-alt'>
                    <div className='pullie'></div>
                </div>
                <div className='window'>
                    <div className='streak'></div>
                </div>
                <div className='window'>
                    <div className='streak'></div>
                </div>
                <div className='window'>
                    <div className='streak'></div>
                </div>
                <div className='window-alt'>
                    <div className='pullie'></div>
                </div>
                <div className='window'>
                    <div className='streak'></div>
                </div>
                <div className='door'>
                    <div className='door-window'></div>
                    <div className='box'></div>
                </div>
            </div>
        </div>
    )
}