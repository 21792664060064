import pdf from 'assets/images/formats/pdf.svg'
import xlsx from 'assets/images/formats/xlsx.svg'
import docs from 'assets/images/formats/docs.svg'

export const getImagePath = (fileType?: string): string => {
  let imageUrl: string
  if (fileType === 'pdf') {
    imageUrl = pdf
  } else if (fileType === 'docx') {
    imageUrl = docs
  } else imageUrl = xlsx
  return imageUrl
}
