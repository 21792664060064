import {useState} from 'react'
import {AGENT_DETAILS} from 'common/enums/texts'
import {ContactDetails} from 'components/ContactDetails'
import {CustomIcon} from 'components/Icon'
import {ICONS} from 'common/enums/icons'
import {getIconPath} from 'components/IconsContainerDetails/helper'

type ContactsContainerDetailsProps = {
    name: string
    image: string
    phone: string
    description?: string
}

export const ContactsContainerDetails: React.FC<ContactsContainerDetailsProps> =
    ({name, image, description, phone}) => {
        const [path] = useState<string>(getIconPath(ICONS.PHONE))

        return (
            <ContactDetails
                name={name}
                title={AGENT_DETAILS.AGENT_DETAILS}
                image={image}
                description={description}
            >
                <a href={`tel:${phone}`}>
                    <CustomIcon
                        title={ICONS.PHONE}
                        image={path}
                    />
                </a>
            </ContactDetails>
        )
    }