import React from 'react'
import {GoogleMap} from '@react-google-maps/api'
import {googleMapOptions, preloadText} from '../consts'
import {Address} from '../Address/Address'
import {Box} from '@material-ui/core'
import {CoordinatesProps} from '../Area'

type CustomMapProps = {
    isLoaded: boolean
    coordinates: CoordinatesProps
    showFullAddress: boolean
}

export const CustomMap: React.FC<CustomMapProps> = ({isLoaded, coordinates, showFullAddress}) => {
    return isLoaded
        ? <GoogleMap
            clickableIcons={false}
            mapContainerStyle={googleMapOptions.mapContainerStyle}
            zoom={googleMapOptions.zoom}
            center={coordinates}
            options={googleMapOptions.optionsMap}
        >
            <Address showFullAddress={showFullAddress} coordinates={coordinates}/>
        </GoogleMap>
        : <Box>{preloadText}</Box>
}