import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { PATHES } from 'routes/enums'
import { TeaserScreen } from 'pages/TeaserScreen'
import { TeaserSign } from 'pages/TeaserSign'
import { TeaserProperty } from 'pages/TeaserProperty'
import Error from 'pages/Error/Error'
import { TeaserConfirmation } from 'pages/TeaserConfirmation'
import { TeaserContextWrapper } from '../context/TeaserContext'
import { TeaserScreenSigned } from 'pages/TeaserScreenSigned'

export const Routing = () => {
  return (
    <Router>
      <TeaserContextWrapper>
        <Routes>
          <Route path={PATHES.TEASER_SCREEN} element={<TeaserScreen />} />
          <Route path={PATHES.TEASER_SCREEN_SIGNED} element={<TeaserScreenSigned />} />
          <Route path={PATHES.TEASER_SIGN} element={<TeaserSign />} />
          <Route path={PATHES.TEASER_PROPERTY} element={<TeaserProperty />} />
          <Route path={PATHES.TEASER_SUCCESS} element={<TeaserConfirmation />} />
          <Route path={PATHES.TEASER_DEFAULT} element={<Error message="Page not found" />} />
        </Routes>
      </TeaserContextWrapper>
    </Router>
  )
}
