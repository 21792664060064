import { Box } from '@material-ui/core'
import { APARTMENT_DETAILS } from 'common/enums/texts'
import { DetailsDescription } from '../DetailsDescription'
import './styles.scss'
import { FONTS_SIZES } from '../DetailsDescription/const'

type ApartmentAdditionalDetails = {
  type?: string
  meters?: number
  floor?: number
  rooms?: number
  totalFloors?: number
}

export const ApartmentDetails: React.FC<ApartmentAdditionalDetails> = ({ floor, type, rooms, meters, totalFloors }) => {
  const emptyValue = '-'
  return (
    <Box className="detailsWrapper" overflow="hidden">
      {<DetailsDescription title={APARTMENT_DETAILS.TYPE_OF_APARTMENT} description={type ?? emptyValue} />}
      {
        <DetailsDescription
          title={APARTMENT_DETAILS.SQUARE_BUILD}
          description={meters ?? emptyValue}
          fontSize={FONTS_SIZES.BIG}
        />
      }
      {
        <DetailsDescription
          title={APARTMENT_DETAILS.FLOOR}
          description={floor !== null ? `${floor}/${totalFloors}` : emptyValue}
          fontSize={FONTS_SIZES.BIG}
        />
      }
      {
        <DetailsDescription
          title={APARTMENT_DETAILS.ROOMS}
          description={rooms ?? emptyValue}
          fontSize={FONTS_SIZES.BIG}
        />
      }
    </Box>
  )
}
