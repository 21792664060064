import {Circle} from '@react-google-maps/api'
import {googleMapOptions} from '../consts'

export type CoordinatesProps = {
    lat: number
    lng: number
}

export const CustomArea: React.FC<CoordinatesProps> = ({lat, lng}) => {

    return (
        <Circle
            center={{lat, lng}}
            radius={googleMapOptions.radius}
            visible={true}
            options={googleMapOptions.optionsCircle}
        />
    )
}
