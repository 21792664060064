import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import { getLabelText } from './helper'
import { idRegex, INPUTS_ERRORS_TEXT, LABELS, addressRegex, nameRegex } from './const'

type CustomInputProps = {
  title: string
  value: string | boolean
  isNumeric: boolean
  handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | MouseEvent) => void
  syncFieldError: Dispatch<SetStateAction<boolean>>
}

export const CustomInput: React.FC<CustomInputProps> = ({ title, value, isNumeric, handleChange, syncFieldError }) => {
  const [label] = useState<string>(() => getLabelText(title))
  const [showError, setShowError] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>('')
  const [touched, setTouched] = useState<boolean>(false)

  useEffect(() => {
    syncFieldError(showError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showError])

  useEffect(() => {
    if (!touched || typeof value !== 'string') return
    else {
      if (label === LABELS.ID) {
        setShowError(!idRegex.test(value))
      } else if (value.length < 2) {
        setShowError(true)
      } else if (label === LABELS.FULLNAME) {
        setShowError(!nameRegex.test(value))
      } else if (label === LABELS.ADDRESS) {
        setShowError(!addressRegex.test(value))
      }
      setErrorText('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onInputLeave = () => {
    if (!touched) {
      setTouched(true)
    }
    if (typeof value !== 'string') {
      return
    }
    if (label === LABELS.ID && value.length !== 9) {
      setShowError(!idRegex.test(value))
      setErrorText(INPUTS_ERRORS_TEXT.teudatZeutLength)
    } else {
      if (value.length < 2) {
        setShowError(true)
      } else if (label === LABELS.FULLNAME) {
        setShowError(!nameRegex.test(value))
      } else if (label === LABELS.ADDRESS) {
        setShowError(!addressRegex.test(value))
      }
      setErrorText(INPUTS_ERRORS_TEXT.default)
    }
  }

  return (
    <TextField
      fullWidth
      name={title}
      value={value}
      label={value ? '' : label}
      variant="filled"
      onChange={handleChange}
      error={showError}
      helperText={showError && errorText}
      onBlur={onInputLeave}
      onTouchEnd={() => setTouched(true)}
      inputProps={isNumeric ? { inputMode: 'numeric' } : {}}
      type={isNumeric ? 'number' : 'text'}
    />
  )
}
