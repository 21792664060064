import { Box } from '@material-ui/core'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { APARTMENT_DETAILS } from 'common/enums/texts'
import { CustomTypography } from '../../Typography'
import { useStyles } from './styles'

type DealDescriptionProps = {
  type: string
  count: string
  description: string
}

export const DealDetails: React.FC<DealDescriptionProps> = ({ type, count, description }) => {
  const styles = useStyles()

  return (
    <Box display="flex" className={styles.infoBlock} flexDirection="row-reverse">
      <Box className={styles.leftBox}>
        <Box display="flex" alignItems="center">
          <CustomTypography component={FONTS_TAG_TYPE.H1} type={FONTS.H1_BLACK_60} value={count} />
        </Box>
        <Box display="flex" alignItems="center">
          <CustomTypography
            component={FONTS_TAG_TYPE.H6}
            type={FONTS.H6_BLACK_40}
            value={`${APARTMENT_DETAILS.PRICE_PER_METER} ${description}`}
          />
        </Box>
      </Box>
      <CustomTypography component={FONTS_TAG_TYPE.H5} type={FONTS.H4_PINK_60} value={type} />
    </Box>
  )
}
