import React from 'react'
import { Box } from '@material-ui/core'
import { BUTTON_VALUES } from './const'
import { WINDOW_TYPES } from 'context/const'
import { TeaserPreviewButton } from '../TeaserPreviewButton'

export const TeaserPreviewHandler: React.FC = () => {
  return (
    <Box display="flex" justifyContent="space-between">
      <TeaserPreviewButton buttonText={BUTTON_VALUES.AGREEMENT} switchType={WINDOW_TYPES.UNSIGNED} />
      <TeaserPreviewButton buttonText={BUTTON_VALUES.PROPERTY} switchType={WINDOW_TYPES.SIGNED} />
    </Box>
  )
}
