import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    attachmentsWrapper: {
      width: '100%',
    },
    attachments: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      rowGap: '16px',
      width: '100%',
    },
  }
})
