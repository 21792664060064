import marker from 'assets/images/marker.png'
import { COLORS } from 'common/enums/colors'
import { RADIUS } from 'common/enums/radius'

const mapContainerStyle = {
  width: '100%',
  height: 300,
  borderRadius: RADIUS.ACCORDION_RADIUS,
}

const customMapStyles = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]

const optionsMap = {
  disableDefaultUI: true,
  scrollwheel: false,
  gestureHandling: 'none',
  styles: customMapStyles,
}

const optionsCircle = {
  strokeColor: COLORS.PINK,
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: COLORS.PINK_LIGHT,
  fillOpacity: 0.35,
  zIndex: 1,
}

export const googleMapOptions = {
  mapContainerStyle,
  optionsMap,
  optionsCircle,
  marker,
  zoom: 15,
  radius: 230,
}

export const preloadText = 'Loading...'
