import {Box} from '@material-ui/core'
import {ReactNode} from 'react'
import {useStyles} from './styles'

type FooterContainerProps = {
    children: ReactNode
}

export const FooterContainer:React.FC<FooterContainerProps> = ({children}) => {
    const styles = useStyles()

    return (
        <Box className={styles.boxFooter}>
            {children}
        </Box>
    )
}
