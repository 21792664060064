import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Box} from '@material-ui/core'
import {useStyles} from '../../styles'
import {DETAILS} from '../../enums'
import {TeaserSignatureDetails} from '../TeaserSignatureDetails'
import {CustomButton} from 'components/buttons/Button'
import {FooterContainer} from 'components/FooterContainer'

type TeaserFooterDetailsProps = {
    signatureUrl?: string
    signedAt?: string
    isSigned?: boolean
}

export const TeaserFooterDetails: React.FC<TeaserFooterDetailsProps> = ({isSigned, signedAt, signatureUrl}) => {
    const {teaserId} = useParams()
    const navigate = useNavigate()
    const styles = useStyles()

    const handlerClick = (): void => navigate(`/teasers/${teaserId}/sign`)

    return (
        <Box>
            {isSigned ? (
                <Box className={styles.boxFooter}>
                    <TeaserSignatureDetails signatureUrl={signatureUrl || ''} signedAt={signedAt || ''}/>
                </Box>
            ) : (
                <FooterContainer>
                    <CustomButton
                        title={DETAILS.SUBSCRIBE}
                        onSign={handlerClick}
                    />
                </FooterContainer>
            )}
        </Box>
    )
}
