import React from 'react'
import {Avatar} from '@material-ui/core'
import {useStyles} from './styles'

export type IconType = {
    id?: string
    title: string
    image?: string
    children?: React.ReactNode
}

export const CustomIcon: React.FC<IconType> = ({children, title, image}) => {
    const styles = useStyles()

    return (
        children
            ? (<Avatar className={styles.button}>{children}</Avatar>)
            : (<Avatar alt={title} src={image} className={styles.icons}/>)
    )
}
