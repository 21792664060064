import React from 'react'
import { CustomTypography } from '../Typography'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { Box } from '@material-ui/core'
import { TaxesLine } from './TaxesLine'
import { houseCommittee, propTaxPrice, taxesBlockTitle } from './const'

type TaxesDetailsProps = {
  houseCommitteePrice?: number
  propertyTaxPrice?: number
}

export const TaxesDetails: React.FC<TaxesDetailsProps> = ({ houseCommitteePrice, propertyTaxPrice }) => {
  return (
    <Box mb={1}>
      <CustomTypography component={FONTS_TAG_TYPE.H3} type={FONTS.H3_BLACK} value={taxesBlockTitle} />
      {!houseCommitteePrice && !propertyTaxPrice ? '-' : null}
      {houseCommitteePrice && <TaxesLine value={houseCommitteePrice} title={houseCommittee} />}
      {propertyTaxPrice && <TaxesLine value={propertyTaxPrice} title={propTaxPrice} />}
    </Box>
  )
}
