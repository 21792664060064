import { makeStyles } from '@material-ui/core'
import { COLORS } from 'common/enums/colors'

export const useStyles = makeStyles(() => {
  return {
    button: {
      fontFamily: 'Noto Sans Hebrew, Helvetica, Noto Sans, Roboto, Arial, sans-serif !important',
      backgroundColor: `${COLORS.PINK} !important`,
      color: COLORS.WHITE,
      maxWidth: 410,
    },
    disabled: {
      backgroundColor: 'lightgray',
    },
  }
})
