import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => {
  return {
    contractWrapper: {
      wordSpacing: '2px',
      '&>*': {
        lineHeight: 1.75,
      },
    },
  }
})
