import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    box: {
      width: '45px',
      height: '55px',
    },
    image: {
      width: '100%',
      height: 'auto',
    },
  }
})
