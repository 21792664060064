import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    box: {
      padding: '15px 0 5px 0',
    },
    boxFlex: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    description: {
      marginTop: 10,
      marginBottom: 10,
    },
  }
})
