import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    infoWrapper: {
      margin: '1.5rem 0',
    },
    info: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '.5rem 0',
    },
    icons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > *': {
        marginLeft: 24,
      },
    },
  }
})
