export const getCurrentPrice = (value: number): string => {
  let newValue = value.toFixed(0).toString()
  let space = 0
  let number = ''

  for (let i = newValue.length - 1; i >= 0; i--) {
    if (space === 3) {
      number = ' ' + number
      space = 0
    }
    number = newValue.charAt(i) + number
    space++
  }

  return number.split(' ').join(',') + ' ₪'
}
