import { COLORS } from 'common/enums/colors'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    icons: {
      width: 25,
      height: 25,
    },
    button: {
      width: 30,
      height: 30,
      color: COLORS.BLACK,
      backgroundColor: COLORS.WHITE,
    },
  }
})
