import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    box: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    avatar: {
      width: 60,
      height: 60,
    },
  }
})
