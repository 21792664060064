import React from 'react'
import {Box} from '@material-ui/core'
import {FONTS, FONTS_TAG_TYPE} from 'common/enums/fonts'
import {CustomDivider} from '../Divider'
import {CustomTypography} from '../Typography'
import {useStyles} from './styles'

interface SignatureDetailsInterface {
    title: string
}

export const SignatureDetails: React.FC<SignatureDetailsInterface> = ({title}) => {
    const styles = useStyles()

    return (
        <Box className={styles.signature}>
            <CustomDivider/>
            <CustomTypography
                component={FONTS_TAG_TYPE.H2}
                type={FONTS.H2_BLACK_16}
                value={title}
            />
        </Box>
    )
}
