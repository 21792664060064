import React from 'react'
import { CustomTypography } from '../../Typography'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { Box } from '@material-ui/core'
import { useStyles } from '../style'
import { getCurrentPrice } from 'components/Accordion/helper'

type TaxesLineProps = {
  title: string
  value: number
}

export const TaxesLine: React.FC<TaxesLineProps> = ({ title, value }) => {
  const styles = useStyles()

  return (
    <Box mt={2} className={styles.taxWrapper}>
      <CustomTypography component={FONTS_TAG_TYPE.P} type={FONTS.P_Regular_Black_60} value={title} />
      <Box>
        <CustomTypography
          component={FONTS_TAG_TYPE.P}
          type={FONTS.P_Regular_Black_60}
          value={getCurrentPrice(value || 0)}
        />
      </Box>
    </Box>
  )
}
