import React, {Dispatch, SetStateAction} from 'react'
import {useStyles} from './styles'
import SignatureCanvas from 'react-signature-canvas'

export type SignatureProps = {
    syncSignature: Dispatch<SetStateAction<string>>
    signatureRef: React.MutableRefObject<any>
}

export const Signature: React.FC<SignatureProps> = ({syncSignature, signatureRef}) => {
    const styles = useStyles()

    const handleChange = () => {
        if (signatureRef?.current) {
            syncSignature(signatureRef.current.toDataURL())
        }
    }

    return (
        <SignatureCanvas
            ref={(ref) => signatureRef.current = ref}
            clearOnResize={false}
            canvasProps={{className: styles.box}}
            onEnd={handleChange}
        />
    )
}
