import React from 'react'
import {Avatar, Box} from '@material-ui/core'
import {useStyles} from './styles'
import {IPerson} from 'interfaces/teaser.interface'
import {CardDescription} from '../CardDescription'
import {DETAILS} from '../CardDescription/enums'
import {CustomIcon} from '../../Icon'
import phone from 'assets/images/actions/call.svg'
import {telHref} from './const'

export const UserCard: React.FC<IPerson> = ({licenseNumber, fullName, image, phoneNumber}) => {
    const styles = useStyles()

    return (
        <Box className={styles.box}>
            <Box className={styles.box}>
                <Avatar
                    alt={fullName}
                    src={image ? image.imageUrl : fullName}
                    className={styles.avatar}
                />

                <CardDescription
                    title={fullName}
                    description={`${DETAILS.LICENSE} ${licenseNumber}`}
                />
            </Box>

            <a href={`${telHref}${phoneNumber}`}>
                <CustomIcon title='call' image={phone}/>
            </a>
        </Box>
    )
}
