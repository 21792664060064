import React from 'react'
import { Box } from '@material-ui/core'
import { CustomAccordion } from 'components/Accordion'
import { useStyles } from '../../styles'
import { PropertyInterface } from 'interfaces/teaser.interface'
import { PREVIEW_TYPE } from '../../const'
import { AGREEMENT_TYPE } from 'pages/TeaserScreen/enums'

type AccordionHelperType = {
  property: PropertyInterface
  isSigned: boolean
  teaserId: string
  showFullAddress: boolean
  showPropertyFullProfile: boolean
  previewType: PREVIEW_TYPE
  isExpanded?: boolean
  agreementType: AGREEMENT_TYPE
}

export const AccordionHelper: React.FC<AccordionHelperType> = ({
  isSigned,
  property,
  showFullAddress,
  previewType,
  isExpanded,
}) => {
  const styles = useStyles()

  return isSigned || previewType === PREVIEW_TYPE.FULL ? (
    <Box key={property.id} className={styles.box}>
      <CustomAccordion showFullAddress={showFullAddress} property={property} isExpanded={isExpanded} />
    </Box>
  ) : (
    <Box key={property.id} className={styles.box}>
      <CustomAccordion showFullAddress={showFullAddress} property={property} isExpanded={isExpanded} />
    </Box>
  )
}
