import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    box: {
      display: 'flex',
      alignItems: 'center',
    },
    boxText: {
      padding: '0 10px',
    },
  }
})
