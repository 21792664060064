import {useLoadScript} from '@react-google-maps/api'
import {Box} from '@material-ui/core'
import {useStyles} from './styles'
import {CustomMap} from './CustomMap/CustomMap'
import {IAddress} from 'interfaces/teaser.interface'

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API || ''

type CustomMapProps = {
    address: IAddress
    showFullAddress: boolean
}

export const Map: React.FC<CustomMapProps> = ({address, showFullAddress}) => {
    const styles = useStyles()
    const coordinates = {lat: parseFloat(address.lat), lng: parseFloat(address.long)}

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: API_KEY,
    })

    return (
        <Box className={styles.box}>
            <CustomMap showFullAddress={showFullAddress} coordinates={coordinates} isLoaded={isLoaded}/>
        </Box>
    )
}
