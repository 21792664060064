import { COLORS } from '../enums/colors'
import { RADIUS } from '../enums/radius'

export const switchTheme = () => ({
  MuiSwitch: {
    root: {
      width: '100%',
      height: 55,
      padding: 0,
      borderRadius: RADIUS.BUTTON_RADIUS,
    },
    switchBase: {
      backgroundColor: COLORS.GREY_DARK,
      margin: 5,
      padding: 0,
    },
    colorSecondary: {
      '&$checked': {
        color: COLORS.WHITE,
        transform: 'translateX(300px) !important',
      },
    },
    thumb: {
      width: 45,
      height: 45,
      margin: 0,
      padding: 0,
    },
    track: {
      backgroundColor: COLORS.PINK,
      opacity: '1 !important',
      '&:after, &:before': {
        color: 'white',
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'center',
      },
      '&:before': {
        content: "'להחליק לאישור'",
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      },
    },
  },
})
