import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    iconWrapper: {
      height: '100%',
      padding: '8px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 20,
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: 5,
    },
  }
})
