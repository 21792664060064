import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    wrapper: {
      marginTop: 20,
    },
    textWrapper: {
      padding: '0 10px',
      marginBottom: 5,
    },
    box: {
      margin: '0 auto',
      marginTop: 15,
    },
    flexBox: {
      margin: '5px auto 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }
})
