import { useState } from 'react'
import { Grid } from '@material-ui/core'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { DEAL_DETAILS } from 'common/enums/texts'
import { PropertyInterface } from 'interfaces/teaser.interface'
import { DETAILS_TYPES } from 'components/Accordion/AccordionSummary/const'
import { getCurrentPrice } from 'components/Accordion/helper'
import { CustomTypography } from 'components/Typography'

export type TeaserPropertyCardProps = {
  property: PropertyInterface
  showFullAddress: boolean
  isExpanded?: boolean
}

export const TeaserPropertyCard: React.FC<TeaserPropertyCardProps> = ({ property, showFullAddress }) => {
  const {
    address: { street, apartment, city, number },
    priceRent,
    priceSell,
  } = property

  const detailsHelper = (detailsType: DETAILS_TYPES): string => {
    switch (detailsType) {
      case DETAILS_TYPES.RENT:
        return priceRent ? `${DEAL_DETAILS.RENT} | ${getCurrentPrice(priceRent)}` : ''
      case DETAILS_TYPES.SELL:
        return priceSell ? `${DEAL_DETAILS.SALE} | ${getCurrentPrice(priceSell)}` : ''
      case DETAILS_TYPES.ADDRESS:
        if (!showFullAddress) {
          return `${street}, ${city}`
        } else if (apartment) {
          return `${street} ${number}/${apartment}, ${city}`
        } else {
          return `${street} ${number}, ${city}`
        }
      default:
        return ''
    }
  }

  const [addressDetails] = useState<string>(() => detailsHelper(DETAILS_TYPES.ADDRESS))
  const [rentDetails] = useState<string>(() => detailsHelper(DETAILS_TYPES.RENT))
  const [sellDetails] = useState<string>(() => detailsHelper(DETAILS_TYPES.SELL))

  return (
    <Grid item xs={12}>
      <CustomTypography component={FONTS_TAG_TYPE.H3} type={FONTS.H3_BLACK} value={addressDetails} />
      {rentDetails && <CustomTypography component={FONTS_TAG_TYPE.H4} type={FONTS.H4_PINK_60} value={rentDetails} />}
      {sellDetails && <CustomTypography component={FONTS_TAG_TYPE.H4} type={FONTS.H4_PINK_60} value={sellDetails} />}
    </Grid>
  )
}
