import React, { useContext, useMemo, useRef } from 'react'
import { Box, Grid } from '@material-ui/core'
import { DESCRIPTION } from 'common/enums/texts'
import { WhiteContainer } from 'components/containers/WhiteContainer'
import { PropertyCharacteristics } from 'components/AmenitiesDetails'
import { PropertyDescription } from 'components/PropertyDescription'
import { ApartmentDescription } from 'components/screensPropertyDetails/ApartamentDescription'
import { AttachmentsDetails } from 'components/AttachmentsDetails'
import { HeaderDetails } from 'components/screensPropertyDetails/HeaderDetails'
import { ContactsContainerDetails } from '../ContactsContainerDetails'
import { Map } from 'components/Map'
import { IPerson, PropertyInterface } from 'interfaces/teaser.interface'
import { TaxesDetails } from 'components/TaxesDetails'
import { ContractInfo } from 'components/ContractInfo'
import { contractTitle } from './const'
import { TeaserContext } from 'context/TeaserContext'
import { transformSignDate } from '../../../TeaserScreen/components/TeaserSignatureDetails/helper'
import { PaginationButton } from 'components/buttons/PaginationButton'
import { DATE_TYPES } from '../../../TeaserScreen/components/TeaserSignatureDetails/const'
import { gainDeviceType } from './helper'
import { useParams } from 'react-router-dom'
import './style.scss'
import { useStyles } from './styles'
import { Puller } from 'components/Puller'

type ContainerDetailsBrokerProps = {
  estateObject: PropertyInterface
  isWebView: boolean
  isDraft: boolean
}

export const ContainerDetailsBroker: React.FC<ContainerDetailsBrokerProps> = ({ isWebView, isDraft, estateObject }) => {
  const emptyValue = '-'
  const { teaser, device } = useContext(TeaserContext)
  const styles = useStyles()
  const { id } = useParams()
  const { address, description, amenities, airDirections, files, agent, houseCommitteePrice, propertyTaxPrice } =
    estateObject

  const exclusivityDate = useMemo<string>(
    () => transformSignDate(teaser?.agreement.exclusivityEnd || '', DATE_TYPES.EXCLUSIVITY),
    [teaser],
  )
  const agentData = useMemo<IPerson>(() => (teaser?.agent ? teaser.agent : agent), [agent, teaser])
  const separatorStyles = useMemo<string>(() => gainDeviceType(isWebView, device), [isWebView, device])
  const teaserDate = useRef<string>(transformSignDate(teaser?.agreement.signedAt || '', DATE_TYPES.PROPERTY))
  const fullAddress = useMemo<string>(() => {
    let fullAddressStr = `${address.street} ${address.number}, ${address.city}`
    if (address.apartment) {
      fullAddressStr = `${address.street} ${address.number}/${address.apartment}, ${address.city}`
    }

    return fullAddressStr
  }, [address])

  return (
    <Box className={isDraft ? styles.mainBoxForDraft : styles.mainBox}>
      <Puller />
      <Grid container item xs={12} direction="column" spacing={3} className="drawerWrapper">
        <Grid item xs={12}>
          <HeaderDetails isExclusively={!!teaser?.agreement.exclusivity} date={exclusivityDate} address={fullAddress} />
        </Grid>

        <Grid item xs={12}>
          <ApartmentDescription estateObject={estateObject} />
        </Grid>

        <Grid item xs={12}>
          <WhiteContainer>
            <PropertyDescription title={DESCRIPTION.PROPERTY_DESCRIPTION} description={description ?? emptyValue} />
          </WhiteContainer>
        </Grid>

        <Grid item xs={12}>
          <Map showFullAddress={true} address={address} />
        </Grid>

        <Grid item xs={12}>
          <PropertyCharacteristics amenities={amenities} airDirections={airDirections ?? [emptyValue]} />
        </Grid>

        <Grid item xs={12}>
          <WhiteContainer>
            <TaxesDetails houseCommitteePrice={houseCommitteePrice} propertyTaxPrice={propertyTaxPrice} />
          </WhiteContainer>
        </Grid>

        {/* <Grid item xs={12}>
          <AdditionalPayments details={paymentsDetails ?? []} />
        </Grid> */}

        <Grid item xs={12}>
          <AttachmentsDetails attachments={files ?? []} />
        </Grid>

        {teaser?.agreement.signedAt && (
          <Grid item xs={12}>
            <ContractInfo link={teaser.agreement.fileUrl || ''} date={teaserDate.current} text={contractTitle} />
          </Grid>
        )}

        <Grid item xs={12}>
          <ContactsContainerDetails
            name={agentData.fullName}
            image={agentData.image?.imageUrl || ''}
            description={agentData.description}
            phone={agentData.phoneNumber}
          />
        </Grid>

        <div className={separatorStyles} />
        {!isWebView && teaser && teaser.properties.length > 1 && id && <PaginationButton teaser={teaser} id={id} />}
      </Grid>
    </Box>
  )
}
