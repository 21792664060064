import { makeStyles } from '@material-ui/core'
import { COLORS } from 'common/enums/colors'
import { RADIUS } from 'common/enums/radius'
import { SHADOW } from 'common/enums/shadow'

export const useStyles = makeStyles(() => {
  return {
    whiteBox: {
      height: 'auto',
      padding: '10px 13px',
      overflow: 'hidden',
      backgroundColor: COLORS.WHITE,
      borderRadius: RADIUS.BOX_RADIUS,
      boxShadow: SHADOW.SHADOW_BOX,
    },
    noPadding: {
      height: 'auto',
      padding: '10px 0',
      overflow: 'hidden',
      backgroundColor: COLORS.WHITE,
      borderRadius: RADIUS.BOX_RADIUS,
      boxShadow: SHADOW.SHADOW_BOX,
    },
  }
})
