import { Avatar, Box } from "@material-ui/core";
import { FONTS, FONTS_TAG_TYPE } from "../../common/enums/fonts";
import { CustomTypography } from "../Typography";
import { useStyles } from "./styles";
import {AccountDetailsProps} from "./types";

export const AccountDetails = (props: AccountDetailsProps) => {
  const { name, image } = props;
  const styles = useStyles();

  return (
    <Box className={styles.box}>
      <Avatar alt={name} src={image} />

      <Box className={styles.boxText}>
        <CustomTypography
          component={FONTS_TAG_TYPE.H4}
          type={FONTS.H4_BLACK}
          value={name}
        />
      </Box>
    </Box>
  );
};
