import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    screen: {
      height: 'auto',
      padding: '0 20px',
    },
  }
})
