import { Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { FontsType } from './types'
import { ElementType } from 'react'
import parse from 'html-react-parser'

type CustomTypographyProps = {
  value: String | number
  component: ElementType
  type: FontsType
  isHtml?: boolean
}

export const CustomTypography: React.FC<CustomTypographyProps> = ({ value, type, component, isHtml }) => {
  const styles = useStyles()

  return (
    <Typography component={component} className={styles[type]}>
      {isHtml ? parse(value.toString()) : value}
    </Typography>
  )
}
