import {Box} from '@material-ui/core'
import {useStyles} from './styles'
import React from 'react'

export const Puller: React.FC = () => {
    const styles = useStyles()

    return (
        <Box className={styles.pullerContainer}>
            <Box className={styles.puller}/>
        </Box>
    )
}
