import React from 'react'
import { Box } from '@material-ui/core'
import { CustomTypography } from 'components/Typography'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { useStyles } from './styles'

type TeaserInstanceProps = {
  details: string[]
}

export const TeaserInstanceDetails: React.FC<TeaserInstanceProps> = ({ details }) => {
  const styles = useStyles()
  return (
    <div id="agreementContent">
      {details.map((item, index) => {
        return (
          <Box key={index} className={styles.contractWrapper}>
            <CustomTypography component={FONTS_TAG_TYPE.P} type={FONTS.P_HTML_Black_60} value={item} isHtml={true} />
          </Box>
        )
      })}
    </div>
  )
}
