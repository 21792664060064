import React, { ErrorInfo } from "react";
import Error from "../pages/Error/Error";

export class ErrorBoundary extends React.Component<
  any,
  { hasError: boolean; error: Error | null; info: ErrorInfo | null }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    if (this.state.hasError) {
      return <Error message={this.state.info} />;
    }
    return this.props.children;
  }
}
