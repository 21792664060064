import {Box} from '@material-ui/core'
import {useStyles} from '../../styles'
import {DETAILS} from '../../enums'
import {CustomTypography} from 'components/Typography'
import {FONTS, FONTS_TAG_TYPE} from 'common/enums/fonts'
import React from 'react'

type SignatureHeaderType = {
    onReset: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const SignatureHeader: React.FC<SignatureHeaderType> = ({onReset}) => {
    const styles = useStyles()

    return (
        <Box className={styles.flexBox}>
            <CustomTypography
                component={FONTS_TAG_TYPE.H3}
                type={FONTS.H3_BLACK}
                value={DETAILS.SIGNATURE}
            />
            <div onClick={onReset}>
                <CustomTypography
                    component={FONTS_TAG_TYPE.H5}
                    type={FONTS.H5_PINK_60}
                    value={DETAILS.SIGNATURE_RESET}
                />
            </div>
        </Box>
    )
}
