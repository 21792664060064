import React from 'react'
import {Box} from '@material-ui/core'

type InstancesWrapperProps = {
    children: React.ReactNode
}

export const InstancesWrapper: React.FC<InstancesWrapperProps> = ({children}) => {
    return (
        <Box>
            {children}
        </Box>
    )
}