import { IData } from 'pages/TeaserScreen/types'

export enum WINDOW_TYPES {
  UNTOUCHED = 'UNTOUCHED',
  UNSIGNED = 'UNSIGNED',
  SIGNED = 'SIGNED',
}

export type StateType = {
  teaser: IData | undefined
  device: string
  activeWindow: WINDOW_TYPES
  gainTeaser: (teaserData: IData) => void
  switchPreviewWindow: (windowType: WINDOW_TYPES, teaser: IData | undefined) => void
}

export const initialState: StateType = {
  teaser: undefined,
  device: '',
  activeWindow: WINDOW_TYPES.UNTOUCHED,
  gainTeaser: () => {},
  switchPreviewWindow: () => {},
}
