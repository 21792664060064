import { UserDataType } from 'pages/TeaserSign'
import { BASE_URL, SIGN, TEASERS, UPLOAD, VIEW } from './const'
import axios from 'axios'
import { ErrorType } from 'types/error'
import { IData } from 'pages/TeaserScreen/types'
import { webViewPreviewDataType } from './types'
import { TeaserPreviewType } from 'pages/TeaserScreen/helper'
import { PREVIEW_TYPE } from '../pages/TeaserScreen/const'

export const instance = axios.create({
  baseURL: BASE_URL,
})

export const fetchTeaser = async (teaserId: string): Promise<ErrorType | IData> => {
  try {
    const response = await instance.get(`${TEASERS}/${teaserId}/${VIEW}`)
    return response.data.data
  } catch (error) {
    return error.response.data
  }
}

export const fetchTeaserPreview = async (
  webViewData: string,
  teaserId: string,
): Promise<ErrorType | TeaserPreviewType> => {
  const dataRequest: webViewPreviewDataType = JSON.parse(webViewData)
  const previewType: PREVIEW_TYPE =
    dataRequest.preview === PREVIEW_TYPE.PARTIAL ? PREVIEW_TYPE.PARTIAL : PREVIEW_TYPE.FULL
  try {
    const response = await instance.get(`${TEASERS}/${teaserId}/preview?preview=${dataRequest.preview}`, {
      headers: {
        Authorization: `Bearer ${dataRequest.token}`,
        'Agency-Id': `${dataRequest.agencyId}`,
      },
    })
    return { data: response.data.data, preview: previewType }
  } catch (error) {
    return error.response.data
  }
}

export const uploadTeaserSignature = async (
  teaserId: string,
  signature: string,
  userData: UserDataType,
  signHelper: Function,
): Promise<undefined | ErrorType> => {
  try {
    const formData = new FormData()
    const blobSignature = await (await fetch(signature)).blob()
    const blobData = new Blob([blobSignature], { type: 'image/png' })
    formData.append('file', blobData)
    const response = await instance.post(`${TEASERS}/${teaserId}/${UPLOAD}`, formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    })
    signHelper(teaserId, { ...userData, signatureUrl: response.data.data.url })
  } catch (error) {
    return error.response.data
  }
}

export const signTeaser = async (teaserId: string, userData: UserDataType): Promise<ErrorType | IData> => {
  try {
    const response = await instance.post(`${TEASERS}/${teaserId}/${SIGN}`, JSON.stringify(userData), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data.data
  } catch (error) {
    return error.response.data
  }
}
