import React from 'react'
import {WhiteContainer} from '../containers/WhiteContainer'
import {Box} from '@material-ui/core'
import {useStyles} from './styles'
import {ContractDetails} from './ContractDetails'

export type ContractInfoProps = {
    link: string
    text: string
    date: string
    extraMargin?: boolean
}

export const ContractInfo: React.FC<ContractInfoProps> = ({link, date, text, extraMargin}) => {
    const classes = useStyles()
    return (
        <Box className={extraMargin ? classes.infoWrapper : ''}>
            <WhiteContainer>
                <ContractDetails link={link} date={date} text={text}/>
            </WhiteContainer>
        </Box>
    )
}