import React, { useContext } from 'react'
import { Wrapper } from 'components/containers/Wrapper'
import { Box } from '@material-ui/core'
import { PREVIEW_TYPE } from '../../../TeaserScreen/const'
import { useStyles } from '../../../TeaserScreen/styles'
import { TeaserContext } from 'context/TeaserContext'
import Error from 'pages/Error/Error'
import { TeaserAgentDetails } from './TeaserAgentDetails'
import { TeaserPreviewHandler } from 'pages/TeaserScreen/components/TeaserPreviewHandler'
import { TeaserPropertyDetails } from 'pages/TeaserScreen/components/TeaserPropertyDetails'
import { TeaserAgreements } from 'pages/TeaserScreen/components/TeaserScreenBody/TeaserAgreements'
import { TeaserFooterDetails } from 'pages/TeaserScreen/components/TeaserFooterDetails'

type TeaserScreenBodySignedType = {
  webViewData: boolean
  previewType: PREVIEW_TYPE
  error: string
  isLoading: boolean
}

export const TeaserScreenBodySigned: React.FC<TeaserScreenBodySignedType> = ({
  webViewData,
  previewType,
  isLoading,
  error,
}) => {
  const { teaser } = useContext(TeaserContext)
  const styles = useStyles()

  if (error.length && !teaser && !webViewData && !isLoading) return <Error message={error} />
  if (!teaser || isLoading) return <></>

  return (
    <>
      {teaser.isDraft && <TeaserPreviewHandler />}
      <Wrapper>
        <TeaserAgentDetails teaser={teaser} />

        <TeaserPropertyDetails
          isSigned={teaser.agreement.signed}
          properties={teaser.properties}
          teaserId={teaser.id}
          showFullAddress={teaser.showFullAddress}
          showPropertyFullProfile={teaser.showPropertyFullProfile}
          previewType={previewType}
          agreementType={teaser.agreement.type}
        />

        <TeaserAgreements teaser={teaser} />

        {!teaser.isDraft && (
          <TeaserFooterDetails
            isSigned={teaser.agreement.signed}
            signedAt={teaser.agreement.signedAt}
            signatureUrl={teaser.agreement.signatureUrl}
          />
        )}

        {!teaser.agreement.signed && !webViewData && <Box className={styles.separator} />}
      </Wrapper>
    </>
  )
}
