import {StylesProvider, jssPreset} from '@material-ui/core'
import {create} from 'jss'
import rtl from 'jss-rtl'
import {ReactNode} from 'react'

type WrapperProps = {
    children: ReactNode
}

const jss = create({plugins: [...jssPreset().plugins, rtl()]})

export const RtlContainer: React.FC<WrapperProps> = ({children}) => {
    return <StylesProvider jss={jss}>{children}</StylesProvider>
}
