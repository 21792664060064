import App from './App'
import ReactDOM from 'react-dom'
import {ErrorBoundary} from 'utils/ErrorBoundary'
import {ThemeProvider} from '@material-ui/core'
import {globalTheme} from 'common/themes/global'
import {RtlContainer} from 'components/RtlContainer'
import './index.scss'

ReactDOM.render(
    <RtlContainer>
        <ThemeProvider theme={globalTheme}>
            <ErrorBoundary>
                <App/>
            </ErrorBoundary>
        </ThemeProvider>
    </RtlContainer>,
    document.getElementById('root')
)
