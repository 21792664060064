import React, { useContext } from 'react'
import { WINDOW_TYPES } from 'context/const'
import { activeStyles, passiveStyles } from '../TeaserPreviewHandler/const'
import { CustomTypography } from 'components/Typography'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { Button } from '@material-ui/core'
import { TeaserContext } from 'context/TeaserContext'

type TeaserPreviewButtonType = {
  switchType: WINDOW_TYPES
  buttonText: string
}

export const TeaserPreviewButton: React.FC<TeaserPreviewButtonType> = ({ switchType, buttonText }) => {
  const { activeWindow, switchPreviewWindow, teaser } = useContext(TeaserContext)
  return (
    <Button
      style={activeWindow === switchType ? activeStyles : passiveStyles}
      onClick={() => switchPreviewWindow(switchType, teaser)}
      type="button"
    >
      <CustomTypography component={FONTS_TAG_TYPE.H4} type={FONTS.H4_BLACK_500} value={buttonText} />
    </Button>
  )
}
