import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    headerText: {
      width: '100%',
    },
  }
})
